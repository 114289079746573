export default {
    silmekont: false,
    solkontrol: false,

    domainId: "bos",
    domainName: "",
    userUID: "",

    // Foto yükleme degiskenleri

    uploadtaskk: null,
    progres1: null,

    AdminDestek: [],

    // Veri çekme Değişkenleri
    BelediyeGenelBilgiler: {
        belediyeadi: "",
        belediyeadresi: "",
        faxnumarasi: "",
        // kuruluslar: "",
        ilceadi: "",
        iletisim: "",
        kapak: "",
        mail: "",
    },
    MobilGenelBilgiler: {
        mobileAPI: "",
        notificationAPI: "",
    },
    BaskanBilgileri: {
        baskanadi: "",
        baskanslogan: "",
        baskanface: "",
        baskantwit: "",
        baskaninsta: "",
        baskanprofil: "",
        ozgecmis: "",
        gorev: "",
        komisyon: "",
        mesaj: "",
        Dosyalar: [],
    },
    IlceBilgiler: {
        tarihce: "",
        Dosyalar: [],
    },
    Birimler: {},
    Destekler: {},
    Uyeler: {},
    Muhtarlar: {},
    Encumenlar: {},
    Personlar: {},
    EskiBaskanlar: {},
    Kararlar: {},
    Mailler: {},
    Mevzuatlar: {},
    Cralanlar: {},
    Stratejikler: {},
    Suanalizler: {},
    MeclisGundemler: {},
    Organizasyonlar: {},
    Otobusler: {},
    Personhaberler: {},
    Ihale: {},
    Sonucihale: {},

    Raporlar: {},
    Raporlar2: {},

    Projeler: {},
    Tadilatlar: {},
    Haberler: {},
    PratikRehber: {},
    Sehitler: {},
    Yerler: {},
    SosyalTesis: {},
    CovidRehber: {},
    Vefatlar: {},
    Bildirimler: {},
    Evlenenler: {},
    Videolar: {},
    Belgeler: {},
    FastDocs: {},

    DuyuruIlanlar: {},
    Menuler: {},
    Slides: {},
    duyuruilankontrol: false,
    menukontrol: false,
    belgekontrol: false,
    fastdockontrol: false,

    evlenenkontrol: false,
    videokontrol: false,
    vefatkontrol: false,
    bildirimkontrol: false,
    yerkontrol: false,
    covidkontrol: false,
    sehitkontrol: false,
    ilcekontrol: false,
    haberkontrol: false,
    pratikkontrol: false,
    projekontrol: false,
    tadilatkontrol: false,
    genelkontrol: false,
    mobilgenelkontrol: false,
    baskankontrol: false,
    raporlarkontrol: false,
    raporlar2kontrol: false,
    admindestekkontrol: false,

    birimlerkontrol: false,
    desteklerkontrol: false,
    muhtarlarkontrol: false,
    encumenlarkontrol: false,
    personlarkontrol: false,
    eskibaskanlarkontrol: false,
    kararlarkontrol: false,
    maillerkontrol: false,
    mevzuatlarkontrol: false,
    cralanlarkontrol: false,
    stratejiklerkontrol: false,
    suanalizlerkontrol: false,
    meclisgundemlerkontrol: false,
    organizasyonlarkontrol: false,
    otobuslerkontrol: false,
    personhaberlerkontrol: false,
    ihalekontrol: false,
    sonucihalekontrol: false,

    uyelerkontrol: false,
    slideskontrol: false,
};