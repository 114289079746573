<template>
  <div></div>
</template>
<script>
export default {
  data() {
    return {
      filename: String,
      file:
        "https://st3.myideasoft.com/idea/cy/49/myassets/products/109/07-302-herby.jpg?revision=1548253070",
      imgbase64: String,
    };
  },
  created() {
    this.$root.$refs.B = this;
  },
  methods: {
    compress(e) {
      for (var valfile of e.target.files) {
        this.filename = valfile.name;
        this.$emit("dosyaisim", this.filename);
        const reader = new FileReader();
        // const eskisi = valfile.size;
        // const sonuc = eskisi / 1024;
        reader.readAsDataURL(valfile);
        reader.onload = () => {
          // const img = new Image();
          // console.log("1:" + img);
          // img.src = event.target.result;
          // const orjw = img.width;
          // const orjh = img.height;
          // const yeniw = orjw * 0.66;
          // const yenih = orjh * 0.66;

          // if (sonuc > 200) {
          //   img.onload = () => {
          //     const elem = document.createElement("canvas");
          //     elem.width = yeniw;
          //     elem.height = yenih;
          //     const ctx = elem.getContext("2d");
          //     // img.width and img.height will contain the original dimensions
          //     ctx.drawImage(img, 0, 0, yeniw, yenih);
          //     ctx.canvas.toBlob(
          //       (blob) => {
          //         const file = new File([blob], fileName, {
          //           type: "image/jpeg",
          //           lastModified: Date.now(),
          //         });
          //         console.log(file);
          //       },
          //       "image/jpeg",
          //       0.6
          //     );
          //     //13822
          //     //5994
          //     this.imagee = valfile;
          //     this.$emit("resim", this.imagee);
          //   };
          // } else {
          //   this.imagee = valfile;
          //   this.$emit("resim", this.imagee);
          // }
          //console.log(img.src);
          if (valfile.type == ("image/jpeg" || "image/png")) this.imgbase64 = reader.result;
          this.file = valfile;
          this.$emit("resim", this.file);
          this.$emit("resimbase64", this.imgbase64);
          reader.onerror = (error) => console.log(error);
        };
      }
    },
  },
};
</script>
