<template>
  <div>
    <img
      class="baskanprofil"
      :src="imageurl"
      style="width: 150px; height: 150px"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    imageurl: String,
  },
};
</script>
<style>
.baskanprofil {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  height: 3%;
}
</style>
