
<template>
  <div>
    <div class="header">
      <div class="header-content">
        <nav class="navbar navbar-expand">
          <div class="collapse navbar-collapse justify-content-between">
            <div class="header-content">
              <button @click="logout" class="btn btn-outline-primary">
                <i class="fa fa-sign-out"></i> Çıkış Yap
              </button>
            </div>
          </div>
        </nav>
      </div>
    </div>

    <div class="content-body">
      <div class="container-fluid">
        <div class="card">
          <div class="col-xl-10 col-lg-10">
            <div class="basic-form">
              <form>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control input-default"
                    placeholder="Belediye Adı"
                    v-model="beladi"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control input-default"
                    placeholder="Domain Adı"
                    v-model="yenidomain"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control input-default"
                    placeholder="Yetkili Adı"
                    v-model="yetkiliAdi"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control input-default"
                    placeholder="Yetkili Mail"
                    v-model="yetkiliMail"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control input-default"
                    placeholder="Yetkili Sifre"
                    v-model="yetkiliSifre"
                  />
                </div>
              </form>
            </div>
          </div>
          <button @click="sil('1625230257166')">
            <i class="fa fa-sign-out"></i> sil Yenisi
          </button>
          <button type="button" class="btn btn-outline-primary" @click="ekle">
            Ekle
          </button>
          <div class="bosluk"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
export default {
  name: "Yeni",
  data() {
    return {
      beladi: "BelediyeAdi",
      yenidomain: "yenidomain",
      yetkiliAdi: "Yetkili Adi",
      yetkiliMail: "Yetkili Mail",
      yetkiliSifre: "Yetkili Sifre",
    };
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.commit("solkontroldegistir", "/");
          this.$router.push("/");
        });
    },
    async sil(ID) {
      const domainnnn = { domainId: ID };
      this.$store.dispatch("yenisitesil", domainnnn);
    },
    async ekle() {
      firebase
        .auth()
        .createUserWithEmailAndPassword(this.yetkiliMail, this.yetkiliSifre)
        .then(async (user) => {
          const usId = user.user.uid;
          const adminbilgiler = {
            adminId: usId,
            domain: this.yenidomain,
            domainId: Date.now().toString(),
            mail: user.user.email,
            yetki: "admin",
          };
          firebase
            .firestore()
            .collection("Adminler")
            .doc(adminbilgiler.adminId)
            .set(adminbilgiler);
          firebase
            .firestore()
            .collection("Domainler")
            .doc(adminbilgiler.domainId)
            .set({
              domainId: adminbilgiler.domainId,
              domainismi: this.yenidomain,
              olusturmatarihi: Date.now().toString(),
            });
          const ekledom = {
            beladi: this.beladi,
            domainId: adminbilgiler.domainId,
          };
          await this.$store.dispatch("yenisiteolustur", ekledom);
          this.$root.$refs.toast.toastgoster("basari", "Yeni Site Eklendi");
        })
        .catch((err) => {
          alert(err);
        });
    },
  },
  components: {},
  mounted() {
    let customscript = document.createElement("script");
    customscript.setAttribute("src", "/js/custom.min.js");
    document.head.appendChild(customscript);
    let deznavscript = document.createElement("script");
    deznavscript.setAttribute("src", "/js/deznav-init.js");
    document.head.appendChild(deznavscript);
  },
};
</script>