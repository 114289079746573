import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import {firebase} from './components/yardimcilar/firebaseinit';
import VueEditor from "vue2-editor";

Vue.config.productionTip = false
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 10,
  newestOnTop: true
});

Vue.use(VueEditor)

let app;

firebase.auth().onAuthStateChanged(()=>{
  if(!app){
   app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
});


  
