<template>
  <div>
    <div v-if="kontrol" id="preloader">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <div id="main-wrapper">
      <div>
        <NavSide sayfa="Anasayfa" />
      </div>
      <div class="content-body rightside-event">
        <Hosgeldiniz />
      </div>
    </div>
  </div>
</template>

<script>
import Hosgeldiniz from "@/components/home/hosgeldinizcard.vue";
import NavSide from "@/components/home/nav-side.vue";
export default {
  name: "Home",
  components: {
    Hosgeldiniz,
    NavSide,
  },
  data() {
    return {
      kontrol: true,
    };
  },
  created() {},
  mounted() {
    document.getElementById("main-wrapper").style.opacity = "0";
    let customscript = document.createElement("script");
    customscript.setAttribute("src", "/js/custom.min.js");
    document.head.appendChild(customscript);
    let deznavscript = document.createElement("script");
    deznavscript.setAttribute("src", "/js/deznav-init.js");
    document.head.appendChild(deznavscript);
    setTimeout(() => {
      this.kontrol = false;
      document.getElementById("main-wrapper").style.opacity = "1";
    }, 100);
    if (localStorage.getItem("reloaded")) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
      localStorage.removeItem("reloaded");
    } else {
      // Set a flag so that we know not to reload the page twice.
      localStorage.setItem("reloaded", "1");
      window.location.reload();
    }
    setTimeout(function () {
      localStorage.removeItem("reloaded");
    }, 3000);
  },
};
</script>

