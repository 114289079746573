import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Login from "../views/Login/Login.vue";
import Genel from "../views/Genel/Genel.vue";
import MobilGenel from "../views/Genel/MobilGenel.vue";
import Kuruluslar from "../views/Genel/Kuruluslar.vue";
import KardesSehirler from "../views/Genel/KardesSehirler.vue";
import {
    firebase
} from "../components/yardimcilar/firebaseinit";
Vue.use(VueRouter);

const routes = [{
        path: "*",
        redirect: "/",
    },
    {
        path: "/",
        name: "Login",
        component: Login,
    },
    {
        path: "/yeni",
        name: "Yeni",
        component: About,
        meta: {
            auth: true,
        },
    },
    {
        path: "/home",
        name: "Home",
        component: Home,
        meta: {
            auth: true,
        },
    },
    {
        path: "/genel",
        name: "Genel",
        component: Genel,
        meta: {
            auth: true,
        },
    },
    {
        path: "/mobilgenel",
        name: "MobilGenel",
        component: MobilGenel,
        meta: {
            auth: true,
        },
    },
    {
        path: "/kurulus",
        name: "Kuruluslar",
        component: Kuruluslar,
        meta: {
            auth: true,
        },
    },
    {
        path: "/kardes-sehirler",
        name: "KardesSehirler",
        component: KardesSehirler,
        meta: {
            auth: true,
        },
    },
    {
        path: "/about",
        name: "About",
        meta: {
            auth: true,
        },
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/About.vue"),
    },

    {
        path: "/bskngenel",
        name: "Baskangenel",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */
                "../views/Baskan/genelbilgiler.vue"
            ),
    },
    {
        path: "/bsknozgcms",
        name: "Baskanozgecmis",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Baskan/ozgecmis.vue"),
    },
    {
        path: "/etikkomisyonu",
        name: "EtikKomisyonu",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */
                "../views/Baskan/EtikKomisyonu.vue"
            ),
    },
    {
        path: "/kvkk",
        name: "KVKK",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */
                "../views/Kurumsal/KVKK.vue"
            ),
    },
    {
        path: "/baskangorev",
        name: "BaskanGorev",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Baskan/BaskanGorev.vue"),
    },
    {
        path: "/bsknmsj",
        name: "Baskanmesaj",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Baskan/baskanmesaj.vue"),
    },
    {
        path: "/bsknglri",
        name: "Baskangaleri",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */
                "../views/Baskan/baskangaleri.vue"
            ),
    },
    {
        path: "/birimler",
        name: "Birimler",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Kurumsal/Birimler.vue"),
    },
    {
        path: "/destekler",
        name: "Destekler",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Kurumsal/Destekler.vue"),
    },
    {
        path: "/meclisuyeler",
        name: "Meclisuyeler",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Kurumsal/Meclisuye.vue"),
    },
    {
        path: "/muhtarlar",
        name: "Muhtarlar",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Kurumsal/Muhtarlar.vue"),
    },
    {
        path: "/personlar",
        name: "Personlar",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Kurumsal/Personlar.vue"),
    },
    {
        path: "/eskibaskanlar",
        name: "EskiBaskanlar",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */
                "../views/Baskan/eskibaskanlar.vue"
            ),
    },
    {
        path: "/mecliskararlari",
        name: "MeclisKararlari",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */
                "../views/Kurumsal/Mecliskararlari.vue"
            ),
    },
    {
        path: "/mesajlar",
        name: "Mesajlar",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Kurumsal/Mesajlar.vue"),
    },
    {
        path: "/mevzuat",
        name: "Mevzuat",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Kurumsal/Mevzuat.vue"),
    },
    {
        path: "/cralan",
        name: "Cralan",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Kurumsal/Cralan.vue"),
    },
    {
        path: "/imartadilat",
        name: "ImarTadilat",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Kurumsal/ImarTadilat.vue"),
    },
    {
        path: "/stratejikplan",
        name: "Stratejik",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Kurumsal/Stratejik.vue"),
    },
    {
        path: "/suanaliz",
        name: "Suanaliz",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */
                "../views/Kurumsal/Suanalizler.vue"
            ),
    },
    {
        path: "/ihalesonuc",
        name: "IhaleSonuc",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */
                "../views/Kurumsal/IhaleSonuc.vue"
            ),
    },
    {
        path: "/meclisgundemi",
        name: "MeclisGundemi",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */
                "../views/Kurumsal/MeclisGundemi.vue"
            ),
    },
    {
        path: "/organizasyon",
        name: "OrganizasyonSemasi",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */
                "../views/Kurumsal/Organizasyon.vue"
            ),
    },
    {
        path: "/otobus",
        name: "Otobusler",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Kurumsal/Otobus.vue"),
    },
    {
        path: "/personhaber",
        name: "PersonHaber",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */
                "../views/Kurumsal/PersonHaber.vue"
            ),
    },
    {
        path: "/ihaleilan",
        name: "IhaleIlan",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Kurumsal/IhaleIlan.vue"),
    },
    {
        path: "/faaliyetraporu",
        name: "FaaliyetRaporu",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */
                "../views/Kurumsal/FaaliyetRaporu.vue"
            ),
    },
    {
        path: "/denetimraporu",
        name: "DenetimRaporu",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */
                "../views/Kurumsal/DenetimRaporu.vue"
            ),
    },
    {
        path: "/encumenler",
        name: "Encumenler",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */
                "../views/Kurumsal/Encumenlar.vue"
            ),
    },
    {
        path: "/tadilatlar",
        name: "Tadilatlar",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Tadilatlar/Tadilatlar.vue"),
    },
    {
        path: "/projeler",
        name: "Projeler",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Projeler/Projeler.vue"),
    },
    {
        path: "/haberler",
        name: "Haberler",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Haberler/Haberler.vue"),
    },
    {
        path: "/pratikrehber",
        name: "PratikRehber",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */
                "../views/Haberler/PratikRehber.vue"
            ),
    },
    {
        path: "/tarihce",
        name: "Tarihce",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Ilce/Tarihce.vue"),
    },
    {
        path: "/sehitlerimiz",
        name: "Sehitler",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Ilce/Sehitlerimiz.vue"),
    },
    {
        path: "/galeri",
        name: "Galer",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Ilce/Galeri.vue"),
    },
    {
        path: "/kentrehberi",
        name: "KentRehberi",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Ilce/KentRehberi.vue"),
    },
    {
        path: "/sosyaltesis",
        name: "SosyalTesis",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Ilce/SosyalTesis.vue"),
    },
    {
        path: "/covidrehber",
        name: "CovidRehber",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Ilce/CovidRehber.vue"),
    },
    {
        path: "/vefatedenler",
        name: "Vefat",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */
                "../views/VefatEdenler/VefatEdenler.vue"
            ),
    },
    {
        path: "/bildirimler",
        name: "MobilBildirimler",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */
                "../views/Mobil/MobilBildirimler.vue"
            ),
    },
    {
        path: "/evlenenler",
        name: "Evlenenler",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */
                "../views/Evlenenler/Evlenenler.vue"
            ),
    },
    {
        path: "/videolar",
        name: "Videolar",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Videolar/Videolar.vue"),
    },
    {
        path: "/belgeler",
        name: "Belgeler",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Belgeler/Belgeler.vue"),
    },
    {
        path: "/belgeler2",
        name: "Belgeler2",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import( /* webpackChunkName: "about" */ "../views/Belgeler/Belgeler2.vue"),
    },
    {
        path: "/duyuruilanlar",
        name: "Duyuruilan",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */
                "../views/Duyuruilan/Duyuruilan.vue"
            ),
    },
    {
        path: "/menuler",
        name: "Menuler",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */
                "../views/Menuler.vue"
            ),
    },
    {
        path: "/anaslider",
        name: "Slider",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */
                "../views/AnaSlider/Anaslider.vue"
            ),
    },
    {
        path: "/AdminDestekPanel",
        name: "AdminDestek",
        meta: {
            auth: true,
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "about" */
                "../views/AdminDestek.vue"
            ),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});
router.beforeEach((to, from, next) => {
    const currentuser = firebase.auth().currentUser;
    const reqauth = to.matched.some((record) => record.meta.auth);
    if (reqauth && !currentuser) {
        next("/");
    } else if (!reqauth && currentuser) {
        next("/home");
    } else {
        next();
    }
});
export default router;