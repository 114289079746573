export const solkontrol = (state) => state.solkontrol;
export const getDomainId = (state) => state.domainId;
export const getDomainName = (state) => state.domainName;

///FOTOĞRAF VERİLERİ GÖSTER
//UploadTaskGöster
export const getUploadTask = (state) => state.uploadTask;
export const getProgress = (state) => state.progres1;

/// ÇEKİLEN VERİLERİ GÖSTER

export const getBelgenel = (state) => state.BelediyeGenelBilgiler;
export const getMobilGenel = (state) => state.MobilGenelBilgiler;
export const getBirimler = (state) => state.Birimler;
export const getDestekler = (state) => state.Destekler;
export const getUyeler = (state) => state.Uyeler;
export const getMuhtarlar = (state) => state.Muhtarlar;
export const getEncumenlar = (state) => state.Encumenlar;
export const getPersonlar = (state) => state.Personlar;
export const getEskiBaskanlar = (state) => state.EskiBaskanlar;
export const getKararlar = (state) => state.Kararlar;
export const getMailler = (state) => state.Mailler;
export const getMevzuatlar = (state) => state.Mevzuatlar;
export const getCralanlar = (state) => state.Cralanlar;
export const getStratejikler = (state) => state.Stratejikler;
export const getSuanalizler = (state) => state.Suanalizler;
export const getMeclisGundemler = (state) => state.MeclisGundemler;
export const getOrganizasyonlar = (state) => state.Organizasyonlar;
export const getOtobusler = (state) => state.Otobusler;
export const getPersonhaberler = (state) => state.Personhaberler;
export const getIhale = (state) => state.Ihale;
export const getSonucihale = (state) => state.Sonucihale;
export const getRaporlar = (state) => state.Raporlar;
export const getRaporlar2 = (state) => state.Raporlar2;
export const getProjeler = (state) => state.Projeler;
export const getTadilatlar = (state) => state.Tadilatlar;
export const getHaberler = (state) => state.Haberler;
export const getPratikRehber = (state) => state.PratikRehber;
export const getBaskan = (state) => state.BaskanBilgileri;
export const getIlce = (state) => state.IlceBilgiler;
export const getSehitler = (state) => state.Sehitler;
export const getYerler = (state) => state.Yerler;
export const getSosyalTesis = (state) => state.SosyalTesis;
export const getCovidRehber = (state) => state.CovidRehber;
export const getVefatlar = (state) => state.Vefatlar;
export const getBildirimler = (state) => state.Bildirimler;
export const getEvlenenler = (state) => state.Evlenenler;
export const getVideolar = (state) => state.Videolar;
export const getBelgeler = (state) => state.Belgeler;
export const getFastDocs = (state) => state.FastDocs;
export const getDuyuruIlanlar = (state) => state.DuyuruIlanlar;
export const getMenuler = (state) => state.Menuler;
export const getSlides = (state) => state.Slides;
export const getAdminDestek = (state) => state.AdminDestek;