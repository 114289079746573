<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12">
          <div
            class="
              welcome-card
              rounded
              pl-5
              pt-5
              pb-4
              mt-0
              position-relative
              mb-3 mb-sm-4 mb-lg-5
              mt-lg-4
            "
          >
            <h4 class="text-warning">
              {{ yazi1fonk }} ZeplinX Yönetim Paneline Hoşgeldiniz!
            </h4>
            <p>
              Kurumsal WebSiteniz için gerekli değişiklikleri bu panel üzerinden
              gerçekleştirebilirsiniz. Sizin için yönetim panelini geliştirmeye
              devam ediyoruz.
            </p>
            <a class="btn btn-warning btn-rounded" href="/destekler"
              >Destek Talebi <i class="las la-long-arrow-alt-right ml-4"></i
            ></a>
            <img
              src="images/svg/welcom-card.svg"
              alt=""
              class="position-absolute"
            />
          </div>
        </div>
        <div class="col-xl-12 col-xxxl-12 col-lg-6">
          <div class="card">
            <div class="card-header border-0 pb-3 d-sm-flex d-block">
              <h4 class="card-title">Ziyaretçi Analizi</h4>
              <div class="d-flex mt-3 mt-sm-0">
                <div class="dropdown">
               <!--    <button
                    type="button"
                    class="
                      btn btn-primary
                      dropdown-toggle
                      light
                      btn-sm btn-rounded
                    "
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Aylık
                  </button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" href="javascript:void(0);"
                      >Daily</a
                    >
                    <a class="dropdown-item" href="javascript:void(0);"
                      >Weekly</a
                    >
                    <a class="dropdown-item" href="javascript:void(0);"
                      >Monthly</a
                    >
                  </div> -->
                </div>
                <div class="dropdown ml-2">
                  <button
                    type="button"
                    class="
                      btn btn-primary
                      light
                      btn-sm btn-rounded
                    "
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    2022
                  </button>
                  <!-- <div class="dropdown-menu">
                    <a class="dropdown-item" href="javascript:void(0);">2020</a>
                    <a class="dropdown-item" href="javascript:void(0);">2019</a>
                    <a class="dropdown-item" href="javascript:void(0);">2018</a>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row mx-0 align-items-center">
                <div
                  class="
                    col-sm-8 col-md-7 col-xxl-7
                    px-0
                    text-center
                    mb-3 mb-sm-0
                  "
                >
                  <div class="d-inline-block" style="min-height: 216.9px">
                    <div
                      class="
                        apexcharts-canvas
                        apexchartscmbidufr
                        apexcharts-theme-light
                      "
                      style="width: 220px; height: 216.9px"
                    >
                      <svg
                        id="SvgjsSvg1042"
                        width="220"
                        height="216.9"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        xmlns:svgjs="http://svgjs.com/svgjs"
                        class="apexcharts-svg"
                        xmlns:data="ApexChartsNS"
                        transform="translate(0, 0)"
                        style="background: transparent"
                      >
                        <g
                          id="SvgjsG1044"
                          class="apexcharts-inner apexcharts-graphical"
                          transform="translate(0, 0)"
                        >
                          <defs id="SvgjsDefs1043">
                            <clipPath id="gridRectMaskcmbidufr">
                              <rect
                                id="SvgjsRect1046"
                                width="226"
                                height="222"
                                x="-3"
                                y="-1"
                                rx="0"
                                ry="0"
                                opacity="1"
                                stroke-width="0"
                                stroke="none"
                                stroke-dasharray="0"
                                fill="#fff"
                              ></rect>
                            </clipPath>
                            <clipPath id="gridRectMarkerMaskcmbidufr">
                              <rect
                                id="SvgjsRect1047"
                                width="224"
                                height="224"
                                x="-2"
                                y="-2"
                                rx="0"
                                ry="0"
                                opacity="1"
                                stroke-width="0"
                                stroke="none"
                                stroke-dasharray="0"
                                fill="#fff"
                              ></rect>
                            </clipPath>
                          </defs>
                          <g id="SvgjsG1048" class="apexcharts-pie">
                            <g
                              id="SvgjsG1049"
                              transform="translate(0, 0) scale(1)"
                            >
                              <circle
                                id="SvgjsCircle1050"
                                r="42.12682926829269"
                                cx="110"
                                cy="110"
                                fill="transparent"
                              ></circle>
                              <g id="SvgjsG1051" class="apexcharts-slices">
                                <g
                                  id="SvgjsG1052"
                                  class="
                                    apexcharts-series apexcharts-pie-series
                                  "
                                  seriesName="seriesx1"
                                  rel="1"
                                  data:realIndex="0"
                                >
                                  <path
                                    id="SvgjsPath1053"
                                    d="M 110 4.682926829268283 A 105.31707317073172 105.31707317073172 0 0 1 215.02258527386186 102.12964526570292 L 152.00903410954476 106.85185810628117 A 42.12682926829269 42.12682926829269 0 0 0 110 67.87317073170732 L 110 4.682926829268283 z"
                                    fill="rgba(255,122,0,1)"
                                    fill-opacity="1"
                                    stroke-opacity="1"
                                    stroke-linecap="butt"
                                    stroke-width="2"
                                    stroke-dasharray="0"
                                    class="
                                      apexcharts-pie-area
                                      apexcharts-donut-slice-0
                                    "
                                    index="0"
                                    j="0"
                                    data:angle="85.71428571428571"
                                    data:startAngle="0"
                                    data:strokeWidth="2"
                                    data:value="25"
                                    data:pathOrig="M 110 4.682926829268283 A 105.31707317073172 105.31707317073172 0 0 1 215.02258527386186 102.12964526570292 L 152.00903410954476 106.85185810628117 A 42.12682926829269 42.12682926829269 0 0 0 110 67.87317073170732 L 110 4.682926829268283 z"
                                    stroke="#ffffff"
                                  ></path>
                                </g>
                                <g
                                  id="SvgjsG1054"
                                  class="
                                    apexcharts-series apexcharts-pie-series
                                  "
                                  seriesName="seriesx2"
                                  rel="2"
                                  data:realIndex="1"
                                >
                                  <path
                                    id="SvgjsPath1055"
                                    d="M 215.02258527386186 102.12964526570292 A 105.31707317073172 105.31707317073172 0 0 1 64.30463449976543 204.88740418543037 L 91.72185379990617 147.95496167417215 A 42.12682926829269 42.12682926829269 0 0 0 152.00903410954476 106.85185810628117 L 215.02258527386186 102.12964526570292 z"
                                    fill="rgba(33,48,184,1)"
                                    fill-opacity="1"
                                    stroke-opacity="1"
                                    stroke-linecap="butt"
                                    stroke-width="2"
                                    stroke-dasharray="0"
                                    class="
                                      apexcharts-pie-area
                                      apexcharts-donut-slice-1
                                    "
                                    index="0"
                                    j="1"
                                    data:angle="120.00000000000001"
                                    data:startAngle="85.71428571428571"
                                    data:strokeWidth="2"
                                    data:value="35"
                                    data:pathOrig="M 215.02258527386186 102.12964526570292 A 105.31707317073172 105.31707317073172 0 0 1 64.30463449976543 204.88740418543037 L 91.72185379990617 147.95496167417215 A 42.12682926829269 42.12682926829269 0 0 0 152.00903410954476 106.85185810628117 L 215.02258527386186 102.12964526570292 z"
                                    stroke="#ffffff"
                                  ></path>
                                </g>
                                <g
                                  id="SvgjsG1056"
                                  class="
                                    apexcharts-series apexcharts-pie-series
                                  "
                                  seriesName="seriesx3"
                                  rel="3"
                                  data:realIndex="2"
                                >
                                  <path
                                    id="SvgjsPath1057"
                                    d="M 64.30463449976543 204.88740418543037 A 105.31707317073172 105.31707317073172 0 0 1 109.98161870323938 4.682928433339029 L 109.99264748129575 67.87317137333561 A 42.12682926829269 42.12682926829269 0 0 0 91.72185379990617 147.95496167417215 L 64.30463449976543 204.88740418543037 z"
                                    fill="rgba(33,184,48,1)"
                                    fill-opacity="1"
                                    stroke-opacity="1"
                                    stroke-linecap="butt"
                                    stroke-width="2"
                                    stroke-dasharray="0"
                                    class="
                                      apexcharts-pie-area
                                      apexcharts-donut-slice-2
                                    "
                                    index="0"
                                    j="2"
                                    data:angle="154.28571428571428"
                                    data:startAngle="205.71428571428572"
                                    data:strokeWidth="2"
                                    data:value="45"
                                    data:pathOrig="M 64.30463449976543 204.88740418543037 A 105.31707317073172 105.31707317073172 0 0 1 109.98161870323938 4.682928433339029 L 109.99264748129575 67.87317137333561 A 42.12682926829269 42.12682926829269 0 0 0 91.72185379990617 147.95496167417215 L 64.30463449976543 204.88740418543037 z"
                                    stroke="#ffffff"
                                  ></path>
                                </g>
                              </g>
                            </g>
                          </g>
                          <line
                            id="SvgjsLine1058"
                            x1="0"
                            y1="0"
                            x2="220"
                            y2="0"
                            stroke="#b6b6b6"
                            stroke-dasharray="0"
                            stroke-width="1"
                            class="apexcharts-ycrosshairs"
                          ></line>
                          <line
                            id="SvgjsLine1059"
                            x1="0"
                            y1="0"
                            x2="220"
                            y2="0"
                            stroke-dasharray="0"
                            stroke-width="0"
                            class="apexcharts-ycrosshairs-hidden"
                          ></line>
                        </g>
                        <g id="SvgjsG1045" class="apexcharts-annotations"></g>
                      </svg>
                      <div class="apexcharts-legend"></div>
                      <div class="apexcharts-tooltip apexcharts-theme-dark">
                        <div class="apexcharts-tooltip-series-group">
                          <span
                            class="apexcharts-tooltip-marker"
                            style="background-color: rgb(255, 122, 0)"
                          ></span>
                          <div
                            class="apexcharts-tooltip-text"
                            style="
                              font-family: Helvetica, Arial, sans-serif;
                              font-size: 12px;
                            "
                          >
                            <div class="apexcharts-tooltip-y-group">
                              <span class="apexcharts-tooltip-text-label"></span
                              ><span
                                class="apexcharts-tooltip-text-value"
                              ></span>
                            </div>
                            <div class="apexcharts-tooltip-z-group">
                              <span
                                class="apexcharts-tooltip-text-z-label"
                              ></span
                              ><span
                                class="apexcharts-tooltip-text-z-value"
                              ></span>
                            </div>
                          </div>
                        </div>
                        <div class="apexcharts-tooltip-series-group">
                          <span
                            class="apexcharts-tooltip-marker"
                            style="background-color: rgb(33, 48, 184)"
                          ></span>
                          <div
                            class="apexcharts-tooltip-text"
                            style="
                              font-family: Helvetica, Arial, sans-serif;
                              font-size: 12px;
                            "
                          >
                            <div class="apexcharts-tooltip-y-group">
                              <span class="apexcharts-tooltip-text-label"></span
                              ><span
                                class="apexcharts-tooltip-text-value"
                              ></span>
                            </div>
                            <div class="apexcharts-tooltip-z-group">
                              <span
                                class="apexcharts-tooltip-text-z-label"
                              ></span
                              ><span
                                class="apexcharts-tooltip-text-z-value"
                              ></span>
                            </div>
                          </div>
                        </div>
                        <div class="apexcharts-tooltip-series-group">
                          <span
                            class="apexcharts-tooltip-marker"
                            style="background-color: rgb(33, 184, 48)"
                          ></span>
                          <div
                            class="apexcharts-tooltip-text"
                            style="
                              font-family: Helvetica, Arial, sans-serif;
                              font-size: 12px;
                            "
                          >
                            <div class="apexcharts-tooltip-y-group">
                              <span class="apexcharts-tooltip-text-label"></span
                              ><span
                                class="apexcharts-tooltip-text-value"
                              ></span>
                            </div>
                            <div class="apexcharts-tooltip-z-group">
                              <span
                                class="apexcharts-tooltip-text-z-label"
                              ></span
                              ><span
                                class="apexcharts-tooltip-text-z-value"
                              ></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="resize-triggers">
                    <div class="expand-trigger">
                      <div style="width: 268px; height: 218px"></div>
                    </div>
                    <div class="contract-trigger"></div>
                  </div>
                </div>
                <div class="col-sm-4 col-md-5 col-xxl-5 px-0">
                  <div class="chart-deta">
                  <!--  <div class="col px-0">
                      <span class="bg-warning"></span>
                      <div class="mx-3">
                         <p class="fs-14">Günlük Ziyaret</p>
                        <h3>{{ (BelediyeGenelBilgiler.websiteDailyCounter) / 30 }}</h3>
                     
                      </div>
                    </div>
                    <div class="col px-0">
                      <span class="bg-primary"></span>
                      <div class="mx-3">
                        <p class="fs-14">Aylık Ziyaret</p>
                        <h3>{{ BelediyeGenelBilgiler.websiteDailyCounter }}</h3>
                      </div>
                    </div>-->
                    <div class="col px-0">
                      <span class="bg-success"></span>
                      <div class="mx-3">
                        <p class="fs-14">Toplam Ziyaret</p>
                        <h3>{{ BelediyeGenelBilgiler.websiteDailyCounter }}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  async created() {
    await this.$store.dispatch("vericekme", "Genel");
    this.BelediyeGenelBilgiler = this.$store.getters.getBelgenel;
  },
  data() {
    return {
      BelediyeGenelBilgiler: {},
    };
  },

  methods: {
    degisgtir1() {
      this.$store.commit("tesyazidegistir", "bu yeni br yazi");
    },
  },
  computed: {
    yazi1fonk() {
      return this.$store.getters.getTestyazi;
    },
  },
};
</script>