<template>
  <div id="app">
    <toast11 />
    <div v-if="kontrol" id="preloader">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <div v-if="routekontrol">
      <router-view />
    </div>
  </div>
</template>


<script>
//import NavSide from '@/components/home/nav-side.vue'
import toast11 from "@/components/yardimcilar/toaster.vue";
export default {
  data() {
    return {
      kontrol: true,
      routekontrol: false,
    };
  },
  components: {
    toast11,
  },
  async created() {
    await this.$store.dispatch("fbgiris");
    this.routekontrol = true;
  },
  mounted() {
    let customscript = document.createElement("script");
    customscript.setAttribute("src", "/js/custom.min.js");
    document.head.appendChild(customscript);
    //  let deznavscript=document.createElement('script')
    // deznavscript.setAttribute('src','/js/deznav-init.js')
    // document.head.appendChild(deznavscript)
    setTimeout(() => {
      this.kontrol = false;
    }, 100);
  },
  computed: {
    solgosterim() {
      return this.$store.getters.solkontrol;
    },
  },
};
</script>
<style>
</style>



