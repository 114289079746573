<template>
  <div>
    <div class="nav-header">
      <router-link to="home">
        <a class="brand-logo">
          <img
            class="brand-title"
            style="max-width: 150px"
            src="images/logo.png"
            alt
          />
        </a>
      </router-link>

      <div class="nav-control">
        <div class="hamburger">
          <span class="line"></span>
          <span class="line"></span>
          <span class="line"></span>
        </div>
      </div>
    </div>
    <div class="header">
      <div class="header-content">
        <nav class="navbar navbar-expand">
          <div class="collapse navbar-collapse justify-content-between">
            <div class="header-left">
              <div class="dashboard_bar">{{ sayfa }}</div>
            </div>

            <div class="header-content">
              <a
                href="/destekler"
                style="width: 150px"
                class="btn btn-warning mr-4 d-none d-sm-block"
                click=""
              >
                Destek <i class="las la-long-arrow-alt-right"></i>
              </a>
              <button
                style="width: 150px"
                @click="logout"
                class="btn btn-outline-primary"
              >
                Çıkış <i class="fa fa-sign-out"></i>
              </button>
            </div>
          </div>
        </nav>
      </div>
    </div>
    <div class="deznav">
      <div class="deznav-scroll">
        <ul class="metismenu" id="menu">
          <li>
            <a
              class="has-arrow ai-icon"
              href="javascript:void()"
              aria-expanded="false"
            >
              <i class="flaticon-381-wifi"></i>
              <span class="nav-text">Genel</span>
            </a>
            <ul aria-expanded="false">
              <li>
                <router-link to="/genel"> Genel Bilgiler </router-link>
              </li>
              <li>
                <router-link to="/covidrehber"> Covid Rehber </router-link>
              </li>
              <li>
                <router-link to="/menuler"> Menuler </router-link>
              </li>
              <li>
                <router-link to="/belgeler"> Belge Örnekleri </router-link>
              </li>

              <li>
                <router-link to="/anaslider"> Slider </router-link>
              </li>
              <li>
                <router-link to="/mesajlar"> Mesajlar </router-link>
              </li>
            </ul>
          </li>

          <li>
            <a
              class="has-arrow ai-icon"
              href="javascript:void()"
              aria-expanded="false"
            >
              <i class="flaticon-381-controls-3"></i>
              <span class="nav-text">Başkan</span>
            </a>
            <ul aria-expanded="false">
              <li>
                <router-link to="/bskngenel">Başkan Genel Bilgiler</router-link>
              </li>
              <li>
                <router-link to="/bsknozgcms">Başkan Özgeçmiş</router-link>
              </li>
              <li>
                <router-link to="/bsknmsj">Başkan Mesaj</router-link>
              </li>
              <li>
                <router-link to="/bsknglri">Başkan Galeri</router-link>
              </li>
              <li>
                <router-link to="/baskangorev">Başkan Görevleri</router-link>
              </li>
              <li>
                <router-link to="/eskibaskanlar">Eski Başkanlar</router-link>
              </li>
              <li>
                <router-link to="/etikkomisyonu">Etik Komisyonu</router-link>
              </li>
            </ul>
          </li>
          <li>
            <a
              class="has-arrow ai-icon"
              href="javascript:void()"
              aria-expanded="false"
            >
              <i class="flaticon-381-internet"></i>
              <span class="nav-text">Kurumsal</span>
            </a>
            <ul aria-expanded="false">
              <li>
                <router-link to="/birimler">Birimler</router-link>
              </li>
              <li>
                <router-link to="/organizasyon"
                  >Organizasyon Şeması</router-link
                >
              </li>
              <li>
                <router-link to="/personlar">Personel Giriş</router-link>
              </li>
              <li>
                <router-link to="/personhaber">Personel Haberler</router-link>
              </li>
              <li>
                <router-link to="/meclisuyeler">Meclis Üyeleri</router-link>
              </li>
              <li>
                <router-link to="/muhtarlar">Muhtarlar</router-link>
              </li>
              <li>
                <router-link to="/kvkk">KVKK</router-link>
              </li>
              <li>
                <router-link to="/encumenler">Encümenler</router-link>
              </li>
            </ul>
          </li>

          <li>
            <a
              class="has-arrow ai-icon"
              href="javascript:void()"
              aria-expanded="false"
            >
              <i class="flaticon-381-book"></i>
              <span class="nav-text">Döküman</span>
            </a>
            <ul aria-expanded="false">
              <li>
                <router-link to="/mecliskararlari"
                  >Meclis Kararları</router-link
                >
              </li>
              <li>
                <router-link to="/mevzuat">Mevzuat & Yasalar</router-link>
              </li>
              <li>
                <router-link to="/faaliyetraporu"
                  >Faaliyet Raporları</router-link
                >
              </li>
              <li>
                <router-link to="/denetimraporu">Denetim Raporları</router-link>
              </li>
              <li v-if="userUID == 'v2qFBCFn8hb4HACj0zTIvPrUtB42'">
                <router-link to="/imartadilat">İmar Tadilat</router-link>
              </li>
              <li v-else>
                <router-link to="/cralan">İcra İlanları</router-link>
              </li>
              <li>
                <router-link to="/ihaleilan">İhale İlanları</router-link>
              </li>
              <li>
                <router-link to="/ihalesonuc">İhale Sonuç İlanları</router-link>
              </li>
              <li>
                <router-link to="/meclisgundemi">Meclis Gündemi</router-link>
              </li>
              <li>
                <router-link to="/stratejikplan">Stratejik Plan</router-link>
              </li>
              <li>
                <router-link to="/suanaliz">Su Analiz Raporları</router-link>
              </li>
            </ul>
          </li>

          <li>
            <a
              class="has-arrow ai-icon"
              href="javascript:void()"
              aria-expanded="false"
            >
              <i class="flaticon-381-notepad"></i>
              <span class="nav-text">İl</span>
            </a>
            <ul aria-expanded="false">
              <li>
                <router-link to="/tarihce">Tarihçe</router-link>
              </li>
              <li>
                <router-link to="/kurulus"
                  >Üye Olduğumuz Kuruluşlar</router-link
                >
              </li>
              <li>
                <router-link to="/kardes-sehirler">Kardeş Şehirler</router-link>
              </li>
              <li>
                <router-link to="/galeri">Galeri</router-link>
              </li>
              <li>
                <router-link to="/otobus">Otobus Güzergahları</router-link>
              </li>
              <li>
                <router-link to="/kentrehberi">Kent Rehberi</router-link>
              </li>
            </ul>
          </li>

          <li>
            <a
              class="has-arrow ai-icon"
              href="javascript:void()"
              aria-expanded="false"
            >
              <i class="flaticon-381-on"></i>
              <span class="nav-text">Güncel</span>
            </a>
            <ul aria-expanded="false">
              <li>
                <router-link to="/projeler"> Projeler </router-link>
              </li>
              <li>
                <router-link to="/tadilatlar"> İmar Tadilat </router-link>
              </li>
              <li>
                <router-link to="/haberler"> Haberler </router-link>
              </li>
              <li>
                <router-link to="/duyuruilanlar"> Duyuru&İlan </router-link>
              </li>
              <li>
                <router-link to="/sehitlerimiz">Şehitlerimiz</router-link>
              </li>
              <li>
                <router-link to="/vefatedenler"> Vefat Edenler </router-link>
              </li>
              <li>
                <router-link to="/evlenenler"> Evlenenler </router-link>
              </li>
              <!-- <li>
                <router-link to="/videolar"> Videolar </router-link>
              </li> -->
            </ul>
          </li>

          <li>
            <a
              class="has-arrow ai-icon"
              href="javascript:void()"
              aria-expanded="false"
            >
              <i class="flaticon-381-smartphone-5"></i>
              <span class="nav-text">Belediye Mobil</span>
            </a>
            <ul aria-expanded="false">
              <li>
                <router-link to="/mobilgenel"> Mobil Genel </router-link>
              </li>
              <li>
                <router-link to="/bildirimler"> Bildirim Sistemi </router-link>
              </li>
            </ul>
          </li>
        </ul>
        <div class="copyright">
          <p>ZeplinX Admin Dashboard© 2021 Tüm Hakları Saklıdır.</p>
          <p class="op5">
            Made with
            <i class="fa fa-heart"></i> by ZeplinX
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from "firebase";
export default {
  data() {
    return {
      userUID: this.$store.state.userUID,
    };
  },
  props: {
    sayfa: String,
  },
  mounted() {
    //document.getElementById("eventt").style.opacity = "0";
  },
  methods: {
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.commit("solkontroldegistir", "/");
          this.$router.push("/");
        });
    },
  },
};
</script>
<style></style>
