<template>
  <div></div>
</template>
<script>
export default {
  data() {
    return {
      filenames: [],
      filename: String,
      files: [],
      file:
        "https://st3.myideasoft.com/idea/cy/49/myassets/products/109/07-302-herby.jpg?revision=1548253070",
      imgbase64: String,
    };
  },
  created() {
    this.$root.$refs.A = this;
  },
  methods: {
    compress(e) {
      this.filenames = [];
      this.files = [];
      for (var valfile of e.target.files) {
        this.filename = valfile.name;
        this.filenames.push(this.fileName);
        this.$emit("dosyaisimleri", this.filenames);
        this.$emit("dosyaisim", this.filename);
        const reader = new FileReader();
     
            this.imgbase64 = reader.result;
            this.file = valfile;
            this.files.push(this.file);
            this.$emit("resim", this.file);
            this.$emit("resimler", this.files);
            this.$emit("resimbase64", this.imgbase64);
         

      }
      var gorunenlerlist = [];
        var sayi = 0;
        for (var vval of this.files) {
          sayi++;
          if (sayi < 6) {
            gorunenlerlist.push(vval);
          }
          gorunenlerlist.push(vval);
        }
        this.$emit("gorunen", this.files);
    },

    // compress2(e) {
    //   this.dosyaisimleri = [];
    //   this.images = [];
    //   for (var valfile of e.target.files) {
    //     const fileName = valfile.name;
    //     this.dosyaismi1 = fileName;
    //     this.dosyaisimleri.push(fileName);
    //     this.$emit("dosyaisimleri", this.dosyaisimleri);
    //     this.$emit("dosyaisim", this.dosyaismi1);
    //     const reader = new FileReader();
    //     const eskisi = valfile.size;
    //     const sonuc = eskisi / 1024;
    //     reader.readAsDataURL(valfile);
    //     reader.onload = event => {
    //       const img = new Image();
    //       img.src = event.target.result;
    //       const orjw = img.width;
    //       const orjh = img.height;
    //       const yeniw = orjw * 0.66;
    //       const yenih = orjh * 0.66;
    //       if (sonuc > 200) {
    //         img.onload = () => {
    //           const elem = document.createElement("canvas");
    //           elem.width = yeniw;
    //           elem.height = yenih;
    //           const ctx = elem.getContext("2d");
    //           // img.width and img.height will contain the original dimensions
    //           ctx.drawImage(img, 0, 0, yeniw, yenih);
    //           ctx.canvas.toBlob(
    //             blob => {
    //               const file = new File([blob], fileName, {
    //                 type: "image/jpeg",
    //                 lastModified: Date.now()
    //               });
    //               console.log(file);
    //             },
    //             "image/jpeg",
    //             0.6
    //           );
    //           //13822
    //           //5994
    //           this.imagee = img.src;
    //           this.images.push(img.src);
    //           this.$emit("resim", this.imagee);
    //           this.$emit("resimler", this.images);
    //         };
    //       } else {
    //         this.imagee = img.src;
    //         this.images.push(img.src);
    //         this.$emit("resim", this.imagee);
    //         this.$emit("resimler", this.images);
    //         var gorunenlerlist = [];
    //         var sayi = 0;
    //         for (var vval of this.images) {
    //           sayi++;
    //           if (sayi < 6) {
    //             gorunenlerlist.push(vval);
    //           }
    //         }
    //         this.$emit("gorunen", gorunenlerlist);
    //       }

    //       reader.onerror = error => console.log(error);
    //     };
    //   }
    //   var gorunenlerlist = [];
    //   var sayi = 0;
    //   for (var vval of this.images) {
    //     sayi++;
    //     if (sayi < 6) {
    //       gorunenlerlist.push(vval);
    //     }
    //     gorunenlerlist.push(vval);
    //   }
    //   this.$emit("gorunen", gorunenlerlist);
    // }

  },
};
</script>
