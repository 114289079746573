import {
    db
} from "@/components/yardimcilar/firebaseinit";
import {
    auth
} from "@/components/yardimcilar/firebaseinit";
import {
    storage
} from "@/components/yardimcilar/firebaseinit";
import {
    firebase
} from "@/components/yardimcilar/firebaseinit";

/// VERİ ÇEKME FONKSİYONLARI
// Giriş kontrol ve domain belirleme
export const fbgiris = async (context) => {
    if (context.state.domainId == "bos") {
        const user = auth.currentUser;
        if (user != null) {
            const userId = user.uid;

            context.commit("setUserUID", userId);
            await db
                .collection("Adminler")
                .where("adminId", "==", userId)
                .get()
                .then((result) => {
                    const docs = result.docs;
                    const doc = docs[0];
                    const data = doc.data();
                    const domainId = data.domainId;
                    const domainnn = data.domain;
                    context.commit("domainIdbelirle", domainId);
                    context.commit("domainnamebelirle", domainnn);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            console.log("kullanici yok");
        }
    } else {
        console.log("fb giris önceden çekildi");
    }
};

export const vericekme = async (context, col) => {
    var kontrol = false;
    switch (col) {
        case "Kararlar":
            kontrol = context.state.kararlarkontrol;
            break;
        case "Mevzuatlar":
            kontrol = context.state.mevzuatlarkontrol;
            break;
        case "Cralanlar":
            kontrol = context.state.cralanlarkontrol;
            break;
        case "Stratejikler":
            kontrol = context.state.stratejiklerkontrol;
            break;
        case "Suanalizler":
            kontrol = context.state.suanalizlerkontrol;
            break;
        case "MeclisGundemler":
            kontrol = context.state.meclisgundemlerkontrol;
            break;
        case "Organizasyonlar":
            kontrol = context.state.organizasyonlarkontrol;
            break;
        case "Otobusler":
            kontrol = context.state.otobuslerkontrol;
            break;
        case "Ihale":
            kontrol = context.state.ihalekontrol;
            break;
        case "Sonucihale":
            kontrol = context.state.sonucihalekontrol;
            break;
        case "Muhtarlar":
            kontrol = context.state.muhtarlarkontrol;
            break;
        case "Personhaberler":
            kontrol = context.state.personhaberlerkontrol;
            break;
        case "Encumenlar":
            kontrol = context.state.encumenlarkontrol;
            break;
        case "EskiBaskanlar":
            kontrol = context.state.eskibaskanlarkontrol;
            break;
        case "Uyeler":
            kontrol = context.state.uyelerkontrol;
            break;
        case "Birimler":
            kontrol = context.state.birimlerkontrol;
            break;
        case "Destekler":
            kontrol = context.state.desteklerkontrol;
            break;
        case "Raporlar":
            kontrol = context.state.raporlarkontrol;
            break;
        case "Raporlar2":
            kontrol = context.state.raporlar2kontrol;
            break;
        case "Baskan":
            kontrol = context.state.baskankontrol;
            break;
        case "Genel":
            kontrol = context.state.genelkontrol;
            break;
        case "MobilGenel":
            kontrol = context.state.mobilgenelkontrol;
            break;
        case "Projeler":
            kontrol = context.state.projekontrol;
            break;
        case "Tadilatlar":
            kontrol = context.state.tadilatkontrol;
            break;
        case "Haberler":
            kontrol = context.state.haberkontrol;
            break;
        case "PratikRehber":
            kontrol = context.state.pratikkontrol;
            break;
        case "Ilce":
            kontrol = context.state.ilcekontrol;
            break;
        case "Sehitler":
            kontrol = context.state.sehitkontrol;
            break;
        case "Yerler":
            kontrol = context.state.yerkontrol;
            break;
        case "SosyalTesis":
            kontrol = context.state.yerkontrol;
            break;
        case "CovidRehber":
            kontrol = context.state.covidkontrol;
            break;
        case "Vefatlar":
            kontrol = context.state.vefatkontrol;
            break;
        case "Bildirimler":
            kontrol = context.state.bildirimkontrol;
            break;
        case "Evlenenler":
            kontrol = context.state.evlenenkontrol;
            break;
        case "Belgeler":
            kontrol = context.state.belgekontrol;
            break;
        case "FastDocs":
            kontrol = context.state.fastdockontrol;
            break;
        case "DuyuruIlanlar":
            kontrol = context.state.duyuruilankontrol;
            break;
        case "Menuler":
            kontrol = context.state.menukontrol;
            break;
        case "Slides":
            kontrol = context.state.slideskontrol;
            break;
        default:
            break;
    }
    if (kontrol == true) {
        console.log("Veri Çekildi");
    } else {
        await db
            .collection(col)
            .doc(context.state.domainId)
            .get()
            .then((result) => {
                const gelendata = result.data();
                const data = gelendata[col];
                var mutdata = {
                    col: col,
                    data: data,
                };
                context.commit("genelmutation", mutdata);
            })
            .catch((err) => {
                alert("vericekmehata1" + err);
            });
    }
};

export const vericekmeAdminDestek = async (context, col) => {
    var kontrol = false;
    switch (col) {
        case "Destekler":
            kontrol = context.state.admindestekkontrol;
            break;
        default:
            break;
    }
    if (kontrol == true) {
        console.log("Veri Çekildi");
    } else {
        let allData = []
        await db
            .collection(col)
            .get()
            .then((result) => {
                result.forEach((doc) => {
                    let gelendata = doc.data()
                    gelendata = gelendata[col]

                    const newValue = Object.values(gelendata)
                    if (newValue.length > 0) {
                        newValue.map((el) => {

                            allData.push(el)

                        })
                    }
                    // console.log(newValue)


                })

                context.commit("adminDestekMutation", allData)
                // const gelendata = result.data()
                // const data = gelendata[col];
                // var mutdata = {
                //     col: col,
                //     data: data,
                // };
                // context.commit("genelmutation", mutdata);
            })
            .catch((err) => {
                alert("vericekmehata1" + err);
            });
    }
};


export const veriguncelle = async (context, databilgiler) => {
    console.log("ben buraya geldim");
    var ekle = {};
    ekle[databilgiler.key] = databilgiler.dataekle;

    await db
        .collection(databilgiler.col)
        .doc(context.state.domainId)
        .update(ekle);
    var col = databilgiler.col;
    await db
        .collection(col)
        .doc(context.state.domainId)
        .get()
        .then((result) => {
            const gelendata = result.data();
            const data = gelendata[col];
            var mutdata = {
                col: col,
                data: data,
            };
            context.commit("genelmutation", mutdata);
        })
        .catch((err) => {
            alert("vericekmehata2 " + err);
        });
};

export const veriguncelledosyali = async (context, databilgiler) => {
    console.log("ben de buraya geldim");
    console.log(databilgiler);
    const domainId = context.state.domainId;
    const domainName = context.state.domainName;
    var urlsayi = 0;
    var kontrol = false;
    if (databilgiler.kapak != "") {
        const dosyaidd = Date.now().toString();
        urlsayi++;
        if (databilgiler.kapak != "ekli") {
            kontrol = true;
            var uploadTask;
            uploadTask = storage
                .ref(domainName + domainId + "/" + databilgiler.ref + "/" + "kapak")
                .put(databilgiler.kapak);
            // var uploadAudio = storage
            //   .ref(domainName + domainId + "/" + databilgiler.ref + "/" + "audio")
            //   .put(databilgiler.dataekle.audioData, "audio_url");
            var bilgi = {
                kontrol1: kontrol,
                dosyaidd: dosyaidd,
                val: databilgiler.kapak,
                uploadtask: uploadTask,
                // uploadaudio: uploadAudio,
                urlsayi: urlsayi,
                databilgiler: databilgiler,
                dosyaurller: databilgiler.dataekle.Dosyalar,
            };
            await dosyaekle(context, bilgi);
        }

        if (databilgiler.dosyakontrol == true) {
            kontrol = false;
            urlsayi = 0;
            for (var val of databilgiler.dosyalar) {
                const dosyaidd = Date.now().toString();
                urlsayi++;
                var uploadTask2;
                uploadTask2 = storage
                    .ref(domainName + domainId + "/" + databilgiler.ref + "/" + dosyaidd)
                    .put(val);
                // var uploadAudio2 = storage
                //   .ref(domainName + domainId + "/" + databilgiler.ref + "/" + "audio")
                //   .put(databilgiler.dataekle.audioData, "audio_url");
                var bilgi2 = {
                    kontrol1: kontrol,
                    dosyaidd: dosyaidd,
                    val: val,
                    uploadtask: uploadTask2,
                    // uploadaudio: uploadAudio2,
                    urlsayi: urlsayi,
                    databilgiler: databilgiler,
                    dosyaurller: databilgiler.dataekle.Dosyalar,
                };
                await dosyaekle(context, bilgi2);
            }
        }
    } else {
        for (var val2 of databilgiler.dosyalar) {
            const dosyaidd = Date.now().toString();
            urlsayi++;
            var uploadTask3;
            uploadTask3 = storage
                .ref(domainName + domainId + "/" + databilgiler.ref + "/" + dosyaidd)
                .put(val2);
            // var uploadAudio3 = storage
            //   .ref(domainName + domainId + "/" + databilgiler.ref + "/" + "audio")
            //   .put(databilgiler.dataekle.audioData, "audio_url");

            var bilgi3 = {
                kontrol1: kontrol,
                dosyaidd: dosyaidd,
                val: val2,
                uploadtask: uploadTask3,
                // uploadaudio: uploadAudio3,
                urlsayi: urlsayi,
                databilgiler: databilgiler,
                dosyaurller: databilgiler.dataekle.Dosyalar,
            };
            await dosyaekle(context, bilgi3);
        }
    }
};

export const anaresim = async (context, databilgiler) => {
    const domainId = context.state.domainId;
    const domainName = context.state.domainName;
    var uploadtask;
    uploadtask = storage
        .ref(domainName + domainId + "/" + databilgiler.ref + "/" + "anaresim")
        .put(databilgiler.anaresim);
    return new Promise((resolve, reject) => {
        uploadtask.on(
            "state_changed",
            async (snapshot) => {
                    var progres1 = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                    context.commit("progresyeni", progres1);
                },
                () => {
                    reject(false);
                },
                async () => {
                    await uploadtask.snapshot.ref
                        .getDownloadURL()
                        .then(async (downloadURL) => {
                            var ekle1 = databilgiler.dataekle;
                            ekle1["anaresim"] = downloadURL;
                            var dat11 = {};
                            dat11[databilgiler.col] = ekle1;
                            await db
                                .collection(databilgiler.col)
                                .doc(context.state.domainId)
                                .update(dat11);
                            resolve(true);
                        });
                }
        );
    });
};
const dosyaekle = async (context, databilgiler) => {
    return new Promise((resolve, reject) => {
        databilgiler.uploadtask.on(
            "state_changed",
            async (snapshot) => {
                    var progres1 = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

                    context.commit("progresyeni", progres1);
                },
                () => {
                    reject(false);
                },
                async () => {
                    // await databilgiler.uploadaudio.snapshot.ref
                    // .getDownloadURL()
                    // .then(async (downloadURL) => {
                    //   console.log(downloadURL)
                    // })
                    console.log("bu 1");

                    // databilgiler.uploadaudio.snapshot.ref
                    //   .getDownloadURL()
                    //   .then((downloadURL) => {
                    //     console.log(downloadURL);
                    //   });

                    await databilgiler.uploadtask.snapshot.ref
                        .getDownloadURL()
                        .then(async (downloadURL) => {
                            console.log(downloadURL);
                            if (
                                databilgiler.databilgiler.kapak != "" &&
                                databilgiler.databilgiler.kapak != "ekli"
                            ) {
                                console.log("bu hatayı bulmam lazım");
                                if (databilgiler.urlsayi == 1 && databilgiler.kontrol1 == true) {
                                    // databilgiler.databilgiler.dataekle.audioData = downloadURL;
                                    console.log("1");
                                    var ekledata1 = databilgiler.databilgiler.dataekle;
                                    console.log("2");
                                    ekledata1["kapak"] = downloadURL;
                                    console.log("3");
                                    databilgiler.databilgiler["dataekle"] = ekledata1;
                                    console.log("4");
                                    //console.log(databilgiler.databilgiler);
                                    await veriguncelle(context, databilgiler.databilgiler);
                                    console.log("5");
                                } else {
                                    const dosya = {
                                        dosyaId: databilgiler.dosyaidd,
                                        dosyaTarihi: Date.now(),
                                        dosyaurl: downloadURL,
                                    };
                                    databilgiler.dosyaurller.push(dosya);
                                    if (
                                        databilgiler.urlsayi ==
                                        databilgiler.databilgiler.dosyalar.length
                                    ) {
                                        var ekledata2 = databilgiler.databilgiler.dataekle;
                                        ekledata2["Dosyalar"] = databilgiler.dosyaurller;
                                        databilgiler.databilgiler["dataekle"] = ekledata2;
                                        await veriguncelle(context, databilgiler.databilgiler);
                                    }
                                }
                            } else {
                                const dosya = {
                                    dosyaId: databilgiler.dosyaidd,
                                    dosyaTarihi: Date.now(),
                                    dosyaurl: downloadURL,
                                };
                                databilgiler.dosyaurller.push(dosya);
                                if (
                                    databilgiler.urlsayi ==
                                    databilgiler.databilgiler.dosyalar.length
                                ) {
                                    var ekledata = databilgiler.databilgiler.dataekle;
                                    ekledata["Dosyalar"] = databilgiler.dosyaurller;
                                    databilgiler.databilgiler["dataekle"] = ekledata;
                                    await veriguncelle(context, databilgiler.databilgiler);
                                }
                            }
                            resolve(true);
                        });
                    console.log("bu 2");
                }
        );
    });
};

export const veridosyasilme = async (context, databilgi) => {
    const domainID = context.state.domainId;
    await storage
        .refFromURL(databilgi.url)
        .delete()
        .then(async () => {
            console.log(domainID);
        })
        .catch(() => {});
};

export const verisilme = async (context, databilgiler) => {

    console.log(databilgiler)
    if (databilgiler.kapak != "") {
        await storage.refFromURL(databilgiler.kapak).delete();
        if (databilgiler.dosyakontrol == true) {
            if (databilgiler.dataekle.Dosyalar.length > 0) {
                const dosyalar = databilgiler.dataekle.Dosyalar;
                for (var dosya of dosyalar) {
                    await storage.refFromURL(dosya.dosyaurl).delete();
                }
            }
        }
    }

    var sil = {};

    sil[databilgiler.key] = firebase.firestore.FieldValue.delete();
    console.log(sil)

    await db
        .collection(databilgiler.col)
        .doc(context.state.domainId)
        .update(sil);
    var col = databilgiler.col;
    await db
        .collection(col)
        .doc(context.state.domainId)
        .get()
        .then((result) => {
            const gelendata = result.data();
            const data = gelendata[col];
            var mutdata = {
                col: col,
                data: data,
            };
            context.commit("genelmutation", mutdata);
        })
        .catch((err) => {
            alert("vericekmehata3 " + err);
        });
};

/// YENİ SİTE OLUŞTUR
export const yenisiteolustur = async (context, yenidomain) => {
    var list = [
        "Baskan",
        "Belgeler",
        "FastDocs",
        "Birimler",
        "Destekler",
        "DuyuruIlanlar",
        "Menuler",
        "Evlenenler",
        "Genel",
        "MobilGenel",
        "Haberler",
        "PratikRehber",
        "Ilce",
        "Kararlar",
        "Mailler",
        "Mevzuatlar",
        "Cralanlar",
        "Stratejikler",
        "Suanalizler",
        "MeclisGundemler",
        "Organizasyonlar",
        "Otobusler",
        "Ihale",
        "Sonucihale",
        "Muhtarlar",
        "Personhaberler",
        "Encumenlar",
        "Personlar",
        "EskiBaskanlar",
        "Projeler",
        "Tadilatlar",
        "Raporlar",
        "Raporlar2",
        "Sehitler",
        "Slides",
        "Uyeler",
        "Vefatlar",
        "Bildirimler",
        "Yerler",
        "SosyalTesis",
        "CovidRehber",
    ];
    for (var val of list) {
        await db
            .collection(val)
            .doc("1606482771210")
            .get()
            .then(async (result) => {
                const gelendata = result.data();
                await db
                    .collection(val)
                    .doc(yenidomain.domainId)
                    .set(gelendata);
            })
            .catch((err) => {
                alert("vericekmehatayenisi" + err);
                context.domain;
            });
    }
};
export const yenisitesil = async (context, yenidomain) => {
    var list = [
        "Baskan",
        "Belgeler",
        "FastDocs",
        "Birimler",
        "Destekler",
        "DuyuruIlanlar",
        "Menuler",
        "Evlenenler",
        "Genel",
        "MobilGenel",
        "Haberler",
        "PratikRehber",
        "Ilce",
        "Kararlar",
        "Mailler",
        "Mevzuatlar",
        "Cralanlar",
        "Stratejikler",
        "Suanalizler",
        "MeclisGundemler",
        "Organizasyonlar",
        "Otobusler",
        "Ihale",
        "Sonucihale",
        "Muhtarlar",
        "Personhaberler",
        "Encumenlar",
        "Personlar",
        "EskiBaskanlar",
        "Projeler",
        "Tadilatlar",
        "Raporlar",
        "Raporlar2",
        "Sehitler",
        "Slides",
        "Uyeler",
        "Vefatlar",
        "Bildirimler",
        "Yerler",
        "SosyalTesis",
        "CovidRehber",
    ];
    for (var val of list) {
        await db
            .collection(val)
            .doc(yenidomain.domainId)
            .delete();
    }
};

export const yenidomainbilgilerolustur = (context, yenidomain) => {
    const belediyebilgiler = {
        belediyeadi: yenidomain.beladi + "Belediyesi",
        belediyeadresi: "Günce",
        faxnumarasi: "0532 169 80 66",
        ilceadi: "Günce",
        iletisim: "0532 169 80 66",
        logourl: " logourl ",
        mail: "info@togdem.com",
    };
    const baskangenel = {
        baskanadi: "Ahmet Günceli",
        baskanslogan: "Günceli Belediye Başkanı",
        baskanface: "baskanface",
        baskantwit: "baskantwit",
        baskaninsta: "baskaninsta",
        baskanprofil: "baskanprofil",
    };
    const baskanozgecmis = {
        text: "Baskan Özgeçmiş",
    };
    const baskangorev = {
        text: "Baskan Görev",
    };
    const etikkomisyonu = {
        text: "Etik Komisyonu",
    };
    const baskanmesaj = {
        text: "Başkan Mesajı",
    };

    const ref1 = db.collection("VeriTabanlari").doc(yenidomain.domainId);
    ref1
        .collection("BelediyeBilgiler")
        .doc("genel")
        .set(belediyebilgiler);
    ref1
        .collection("BaskanBilgiler")
        .doc("genel")
        .set(baskangenel);
    ref1
        .collection("BaskanBilgiler")
        .doc("ozgecmis")
        .set(baskanozgecmis);
    ref1
        .collection("BaskanBilgiler")
        .doc("gorev")
        .set(baskangorev);
    ref1
        .collection("BaskanBilgiler")
        .doc("komisyon")
        .set(etikkomisyonu);
    ref1
        .collection("BaskanBilgiler")
        .doc("mesaj")
        .set(baskanmesaj);
    ref1
        .collection("BaskanBilgiler")
        .doc("mesaj")
        .set(baskanmesaj);
    var i = 0;
    for (i = 0; i < 6; i++) {
        const idbirim = "Id Birim " + i;
        const birim = {
            birimId: idbirim,
            birimadi: "Birim" + i,
            birimaciklamasi: "Birim Açıklaması " + i,
        };
        ref1
            .collection("Birimler")
            .doc(idbirim)
            .set(birim);

        const iddestek = "Id Destek " + i;
        const destek = {
            destekId: iddestek,
            destekadi: "Destek" + i,
            destekaciklamasi: "Destek Açıklaması " + i,
        };
        ref1
            .collection("Destekler")
            .doc(iddestek)
            .set(destek);

        const idmeclisuye = "Id Meclisuyesi " + i;
        const meclisuye = {
            uyeId: idmeclisuye,
            uyeAdi: "Meclisuyesi" + i,
            uyePartisi: i + " Partisi",
            uyeFoto: "Foto" + i,
        };
        ref1
            .collection("MeclisUyeleri")
            .doc(idmeclisuye)
            .set(meclisuye);

        const idmuhtar = "Id Muhtar " + i;
        const muhtar = {
            muhtarId: idmuhtar,
            muhtarAdi: "Muhtar" + i,
            mahalleAdi: i + " Mahallesi",
            muhtarTel: "Tel" + i,
            muhtarlikAdresi: "Adres" + i,
            muhtarFoto: "Foto" + i,
        };
        ref1
            .collection("Muhtarlar")
            .doc(idmuhtar)
            .set(muhtar);

        const idencumen = "Id Encumen " + i;
        const encumen = {
            encumenId: idencumen,
            encumenAdi: "Encumen" + i,
            encumenTel: "Tel" + i,
            encumenlikAdresi: "Adres" + i,
            encumenFoto: "Foto" + i,
        };
        ref1
            .collection("Encumenlar")
            .doc(idencumen)
            .set(encumen);

        const idperson = "Id Person " + i;
        const person = {
            personId: idperson,
            personAdi: "Person" + i,
            personSifre: i + " Sifresi",
            adSoyad: "Ad Soyad" + i,
            // personlikAdresi: 'Adres' + i,
            // personFoto: 'Foto' + i
        };
        ref1
            .collection("Personlar")
            .doc(idperson)
            .set(person);

        const ideskibaskan = "Id Eski Baskan " + i;
        const eskibaskan = {
            eskibaskanId: ideskibaskan,
            eskibaskanAdi: "Eski Baskan" + i,
            eskibaskanTarihi: Date.now().toString(),
            eskibaskanFoto: "Foto" + i,
        };
        ref1
            .collection("Eski Baskan")
            .doc(ideskibaskan)
            .set(eskibaskan);

        const kararId = "Id Karar " + i;
        const karar = {
            kararId: kararId,
            kararAdi: "Karar" + i,
            kararTarihi: Date.now().toString(),
            karardosyasi: "Dosya1",
        };
        ref1
            .collection("Kararlar")
            .doc(kararId)
            .set(karar);

        const mailId = "Id Mail " + i;
        const mail = {
            mailId: mailId,
            mailAdi: "Mail" + i,
            mailTarihi: Date.now().toString(),
            maildosyasi: "Dosya1",
        };
        ref1
            .collection("Mailler")
            .doc(mailId)
            .set(mail);

        const mevzuatId = "Id Mevzuat " + i;
        const mevzuat = {
            mevzuatId: mevzuatId,
            mevzuatAdi: "Mevzuat" + i,
            mevzuatTarihi: Date.now().toString(),
            mevzuatdosyasi: "Dosya1",
        };
        ref1
            .collection("Mevzuatlar")
            .doc(mevzuatId)
            .set(mevzuat);

        const cralanId = "Id Cralan " + i;
        const cralan = {
            cralanId: cralanId,
            cralanAdi: "Cralan" + i,
            cralanTarihi: Date.now().toString(),
            cralanBitisi: "cralanBitisi1",
            cralandosyasi: "Dosya1",
        };
        ref1
            .collection("Cralanlar")
            .doc(cralanId)
            .set(cralan);

        const stratejikId = "Id Stratejik " + i;
        const stratejik = {
            stratejikId: stratejikId,
            stratejikAdi: "Stratejik" + i,
            stratejikTarihi: Date.now().toString(),
            stratejikBitisi: "stratejikBitisi1",
            stratejikdosyasi: "Dosya1",
        };
        ref1
            .collection("Stratejikler")
            .doc(stratejikId)
            .set(stratejik);

        const suanalizId = "Id Suanaliz " + i;
        const suanaliz = {
            suanalizId: suanalizId,
            suanalizAdi: "Suanaliz" + i,
            suanalizTarihi: Date.now().toString(),
            suanalizBitisi: "suanalizBitisi1",
            suanalizdosyasi: "Dosya1",
        };
        ref1
            .collection("Suanalizler")
            .doc(suanalizId)
            .set(suanaliz);

        const meclisgundemId = "Id meclisgundem " + i;
        const meclisgundem = {
            meclisgundemId: meclisgundemId,
            meclisgundemAdi: "meclisgundem" + i,
            meclisgundemTarihi: Date.now().toString(),
            meclisgundemBitisi: "meclisgundemBitisi1",
            meclisgundemdosyasi: "Dosya1",
        };
        ref1
            .collection("MeclisGundemler")
            .doc(meclisgundemId)
            .set(meclisgundem);

        const organizasyonId = "Id Organizasyon " + i;
        const organizasyon = {
            organizasyonId: organizasyonId,
            organizasyonAdi: "Organizasyon" + i,
            organizasyonTarihi: Date.now().toString(),
            // organizasyonBitisi: "organizasyonBitisi1",
            organizasyondosyasi: "Dosya1",
        };
        ref1
            .collection("Organizasyonlar")
            .doc(organizasyonId)
            .set(organizasyon);

        const otobusId = "Id Otobus " + i;
        const otobus = {
            otobusId: otobusId,
            otobusAdi: "Otobus" + i,
            otobusTarihi: Date.now().toString(),
            otobusdosyasi: "Dosya1",
        };
        ref1
            .collection("Otobusler")
            .doc(otobusId)
            .set(otobus);

        const ihaleId = "Id Ihale " + i;
        const ihale = {
            ihaleId: ihaleId,
            ihaleAdi: "Ihale" + i,
            ihaleTarihi: Date.now().toString(),
            ihaledosyasi: "Dosya1",
        };
        ref1
            .collection("Ihale")
            .doc(ihaleId)
            .set(ihale);

        const sonucihaleId = "Id sonucihale " + i;
        const sonucihale = {
            sonucihaleId: sonucihaleId,
            sonucihaleAdi: "sonucihale" + i,
            sonucihaleTarihi: Date.now().toString(),
            sonucihaledosyasi: "Dosya1",
        };
        ref1
            .collection("Sonucihale")
            .doc(sonucihaleId)
            .set(sonucihale);

        const personhaberId = "Id personhaber " + i;
        const personhaber = {
            personhaberId: personhaberId,
            personhaberAdi: "personhaber" + i,
            personhaberTarihi: Date.now().toString(),
            personhaberdosyasi: "Dosya1",
        };
        ref1
            .collection("Personhaberler")
            .doc(personhaberId)
            .set(personhaber);

        const raporId = "Id Rapor " + i;
        const rapor = {
            raporId: raporId,
            raporAdi: "Rapor" + i,
            rapordonemi: "2020",
            raporTarihi: Date.now().toString(),
            rapordosyasi: "Dosya1",
        };
        ref1
            .collection("Raporlar")
            .doc(raporId)
            .set(rapor);

        const rapor2Id = "Id Rapor " + i;
        const rapor2 = {
            rapor2Id: rapor2Id,
            rapor2Adi: "Rapor2" + i,
            rapor2donemi: "2020",
            rapor2Tarihi: Date.now().toString(),
            rapor2dosyasi: "Dosya1",
        };
        ref1
            .collection("Raporlar2")
            .doc(rapor2Id)
            .set(rapor2);

        // const idencumen = "Id Encumen " + i;
        // const encumen = {
        //   encumenId: idencumen,
        //   encumenAdi: "Encümen" + i,
        //   encumenFoto: "Foto" + i,
        // };
        // ref1
        //   .collection("Encumenler")
        //   .doc(idencumen)
        //   .set(encumen);

        const projeId = "Id Proje " + i;
        const proje = {
            projeId: projeId,
            projeAdi: "Proje" + i,
            projeAciklamasi: "Proje Aciklama " + i,
            projeDurum: "",
            projeTarihi: Date.now().toString(),
            projekapak: "kapakurl",
        };
        ref1
            .collection("Projeler")
            .doc(projeId)
            .set(proje);

        const tadilatId = "Id Tadilat " + i;
        const tadilat = {
            tadilatId: tadilatId,
            tadilatAdi: "tadilat" + i,
            tadilatAciklamasi: "tadilat Aciklama " + i,
            tadilatDurum: "",
            tadilatTarihi: Date.now().toString(),
            tadilatkapak: "kapakurl",
        };
        ref1
            .collection("Tadilatlar")
            .doc(tadilatId)
            .set(tadilat);

        const Idhaber = "Id Haber " + i;
        const haber = {
            haberId: Idhaber,
            haberAdi: "Proje" + i,
            haberAciklamasi: "Proje Aciklama " + i,
            youtubeLink: "Proje Link " + i,
            giristarih: Date.now().toString(),
            haberTarihi: Date.now().toString(),
            haberkapak: "kapakurl",
        };
        ref1
            .collection("Haberler")
            .doc(Idhaber)
            .set(haber);

        const Idpratik = "Id Pratik " + i;
        const pratik = {
            pratikId: Idpratik,
            pratikAdi: "pratik" + i,
            pratikAciklamasi: "pratik Aciklama " + i,
            giristarih: Date.now().toString(),
            pratikTarihi: Date.now().toString(),
            pratikkapak: "pratikurl",
        };
        ref1
            .collection("PratikRehber")
            .doc(Idpratik)
            .set(pratik);

        const Idsehit = "Id_Sehit" + i;
        const sehit = {
            sehitId: Idsehit,
            sehitadi: "Sehit" + i,
            dogumtarihi: "01.01.0001",
            fotourl: "fotourl",
            metin: "Taziye Metin" + i,
            tarih: "01.01.0001",
            yer: "Yer" + i,
        };
        ref1
            .collection("Sehitler")
            .doc(Idsehit)
            .set(sehit);

        const Idyer = "Id_Yer" + i;
        const yer = {
            giristarih: Date.now().toString(),
            yerId: Idyer,
            yerAdi: "Yer" + i,
            yerAciklamasi: "Aciklama" + i,
            enlem: "long",
            boylam: "long",
            yerkapakurl: "kapakurl",
            yerturu: "Tarihi_Yer",
        };
        ref1
            .collection("Yerler")
            .doc(Idyer)
            .set(yer);

        const Idsosyal = "Id_Sosyal" + i;
        const sosyal = {
            giristarih: Date.now().toString(),
            sosyalId: Idsosyal,
            sosyalAdi: "Sosyal" + i,
            sosyalAciklamasi: "Aciklama" + i,
            enlem: "long",
            boylam: "long",
            sosyalkapakurl: "kapakurl",
            sosyalturu: "Tarihi_Yer",
        };
        ref1
            .collection("SosyalTesis")
            .doc(Idsosyal)
            .set(sosyal);

        const Idcovid = "Id_Covid" + i;
        const covid = {
            giristarih: Date.now().toString(),
            covidId: Idcovid,
            covidAdi: "Covid" + i,
            covidAciklamasi: "Aciklama" + i,
            covidBilgi: "",
            covidkapakurl: "kapakurl",
            covidturu: "Tarihi_Yer",
        };
        ref1
            .collection("CovidRehber")
            .doc(Idcovid)
            .set(covid);

        const Idvefat = "Id_vefat" + i;
        const vefat = {
            vefatId: Idvefat,
            adi: "Vefat" + i,
            irtibatkisi: "İrtibat" + i,
            tarih: "01.01.0001",
            taziye: "Taziye Metni",
            vakit: "Vakit",
        };
        ref1
            .collection("Vefatlar")
            .doc(Idvefat)
            .set(vefat);

        const Idbildirim = "Id_bildirim" + i;
        const bildirim = {
            bildirimId: Idbildirim,
            bildirimAdi: "bildirimAd" + i,
            bildirimBilgi: "bildirimAciklama" + i,
            bildirimTarihi: "01.01.0001",
        };
        ref1
            .collection("Bildirimler")
            .doc(Idbildirim)
            .set(bildirim);

        const Idevlenen = "Id_evlenen" + i;
        const evlenen = {
            evlenenId: Idevlenen,
            damatAdi: "Damat" + i,
            gelinAdi: "Gelin" + i,
            tarih: "01.01.0001-12:00",
            metin: "Evlilik Metni",
            salon: "Belediye Nikah Salonu",
        };
        ref1
            .collection("Evlenenler")
            .doc(Idevlenen)
            .set(evlenen);


        const Idbelge = "Id_belge" + i;
        const belge = {
            belgeId: Idbelge,
            belgeAdi: "Belge" + i,
            belgeTarihi: Date.now().toString(),
            belgeurl: "Belge Url",
        };
        ref1
            .collection("Belgeler")
            .doc(Idbelge)
            .set(belge);

        const Idfastdoc = "Id_fastdoc" + i;
        const fastdoc = {
            fastdocId: Idfastdoc,
            fastdocAdi: "fastdoc" + i,
            fastdocTarihi: Date.now().toString(),
            fastdocurl: "fastdoc Url",
        };
        ref1
            .collection("FastDocs")
            .doc(Idfastdoc)
            .set(fastdoc);

        const Idduyuruilan = "Id_duyuru_ilan" + i;
        const duyuruilan = {
            duyuruilanId: Idduyuruilan,
            duyuruilanaciklama: "Aciklama" + i,
            duyuruilanAdi: "Duyuruİlan" + i,
            duyurilanKapakurl: "Kapak Url",
            duyuruilanTarihi: "01.01.0001",
            giristarih: Date.now().toString(),
        };
        ref1
            .collection("DuyuruIlan")
            .doc(Idduyuruilan)
            .set(duyuruilan);

        const Idmenu = "Id_duyuru_ilan" + i;
        const menu = {
            menuId: Idmenu,
            menuaciklama: "menuAciklama" + i,
            menuAdi: "MenuAd" + i,
            menuKapakurl: "Kapak Url",
            menuTarihi: "01.01.0001",
            giristarih: Date.now().toString(),
        };
        ref1
            .collection("Menu")
            .doc(Idmenu)
            .set(menu);

        const Idvideolar = "Id_Videolar" + i;
        const video = {
            videoId: Idvideolar,
            videoAdi: "Video" + i,
            videoKapakurl: "Kapak Url",
            videoTarihi: "01.01.0001",
            videoUrl: "Video Url " + i,
        };
        ref1
            .collection("Videolar")
            .doc(Idvideolar)
            .set(video);
    }
    const tarihce = {
        tarihcemetin: "Belediye Tarihçe",
        tarihcekapak: "kapakurltarikce",
    };
    ref1
        .collection("BelediyeBilgiler")
        .doc("tarihce")
        .set(tarihce);
};