<template>
  <div>
    <div v-if="kontrol" id="preloader">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
        <p>{{ progress }}</p>
      </div>
    </div>
    <div id="main-wrapper">
      <NavSide sayfa="Mobil Genel Bilgiler" />
      <div class="content-body">
        <link href="vendor/jqvmap/css/jqvmap.min.css" rel="stylesheet" />
        <link rel="stylesheet" href="vendor/chartist/css/chartist.min.css" />
        <link
          href="vendor/bootstrap-select/dist/css/bootstrap-select.min.css"
          rel="stylesheet"
        />
        <link
          href="vendor/bootstrap-datetimepicker/css/bootstrap-datetimepicker.min.css"
          rel="stylesheet"
        />
        <link href="css/style.css" rel="stylesheet" />
        <link
          href="../../cdn.lineicons.com/2.0/LineIcons.css"
          rel="stylesheet"
        />
        <div class="container-fluid">
          <div class="card">
            <div class="row p-5">
              <div class="col-xl-6 col-lg-6">
                <div class="basic-form">
                  <form>
                    <p class="text-center"><strong>Mobil Bilgiler</strong></p>
                    <hr />
                    <div class="form-group">
                      <span class="solGenelBilgi">Mobil Bilgi 1</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Mobil Bilgi 1"
                        v-model="MobilGenelBilgiler.mobilBilgi1"
                      />
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">Mobil Bilgi 2</span>

                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Mobil Bilgi 2"
                        v-model="MobilGenelBilgiler.mobilBilgi2"
                      />
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">Mobil Bilgi 3</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Mobil Bilgi 3"
                        v-model="MobilGenelBilgiler.mobilBilgi3"
                      />
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">Mobil Bilgi 4</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Mobil Bilgi 4"
                        v-model="MobilGenelBilgiler.mobilBilgi4"
                      />
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6">
                <div class="basic-form">
                  <form>
                    <p class="text-center"><strong>API Bilgileri</strong></p>
                    <hr />
                    <div class="form-group">
                      <span class="solGenelBilgi">Notification API</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Notification API"
                        v-model="MobilGenelBilgiler.notificationAPI"
                      />
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">Mobile API</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Mobile API"
                        v-model="MobilGenelBilgiler.mobileAPI"
                      />
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">Weather API</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Weather API"
                        v-model="MobilGenelBilgiler.weatherAPI"
                      />
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">Maps API</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Maps API"
                        v-model="MobilGenelBilgiler.mapsAPI"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <button
              id="mdbtn1"
              type="button"
              class="btn btn-outline-primary ml-4 mr-4"
              data-toggle="modal"
              @click="genelfonk()"
            >
              Güncelle
            </button>
            <div class="bosluk"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavSide from "@/components/home/nav-side.vue";
export default {
  name: "MobilGenel",
  data() {
    return {
      kontrol: true,
      MobilGenelBilgiler: {},
    };
  },
  computed: {
    progress() {
      return this.$store.getters.getProgress;
    },
  },
  async created() {
    await this.$store.dispatch("vericekme", "MobilGenel");
    this.MobilGenelBilgiler = {
      ...this.MobilGenelBilgiler,
      ...this.$store.getters.getMobilGenel,
    };
    console.log(this.MobilGenelBilgiler);
  },
  components: {
    NavSide,
  },

  methods: {
    async genelfonk() {
      const dataekle = {
        col: "MobilGenel",
        key: "MobilGenel",
        dataekle: this.MobilGenelBilgiler,
      };
      await this.$store.dispatch("veriguncelle", dataekle);
      this.$root.$refs.toast.toastgoster("basari", "Güncelleme Başarılı");
    },
    // toBase64() {
    //   if (typeof this.MobilGenelBilgiler.kapak != "string") {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(this.MobilGenelBilgiler.kapak);
    //     reader.onload = () => {
    //       this.imageBase64 = reader.result;
    //     };
    //     reader.onerror = (error) => console.log(error);
    //   } else this.imageBase64 = this.MobilGenelBilgiler.kapak;
    // },
  },
  mounted() {
    document.getElementById("main-wrapper").style.opacity = "0";
    let customscript = document.createElement("script");
    customscript.setAttribute("src", "/js/custom.min.js");
    document.head.appendChild(customscript);

    let colorscript = document.createElement("script");
    colorscript.setAttribute(
      "src",
      "/js/plugins-init/jquery-asColorPicker.init.js"
    );
    document.head.appendChild(colorscript);

    let deznavscript = document.createElement("script");
    deznavscript.setAttribute("src", "/js/deznav-init.js");
    document.head.appendChild(deznavscript);
    setTimeout(() => {
      this.kontrol = false;
      document.getElementById("main-wrapper").style.opacity = "1";
    }, 100);
  },
};
</script>
<style>
.bosluk {
  padding-bottom: 100px;
}
</style>
