<template>
  <div>
    <div v-if="kontrol" id="preloader">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
        <p>{{ progress }}</p>
      </div>
    </div>
    <div id="main-wrapper">
      <NavSide sayfa="Genel Bilgiler" />
      <div class="content-body">
        <link href="vendor/jqvmap/css/jqvmap.min.css" rel="stylesheet" />
        <link rel="stylesheet" href="vendor/chartist/css/chartist.min.css" />
        <link
          href="vendor/bootstrap-select/dist/css/bootstrap-select.min.css"
          rel="stylesheet"
        />
        <link
          href="vendor/bootstrap-datetimepicker/css/bootstrap-datetimepicker.min.css"
          rel="stylesheet"
        />
        <link href="css/style.css" rel="stylesheet" />
        <link
          href="../../cdn.lineicons.com/2.0/LineIcons.css"
          rel="stylesheet"
        />
        <div class="container-fluid">
          <div class="card">
            <!-- Logo Ve Kapak Bölümü -->
            <div class="row p-1">
              <div class="col-sm-6 col-lg-6">
                <dosyasecbtn :dosyaismi="dsyisim" />
                <profilresim :imageurl="BelediyeGenelBilgiler.kapak" />
                <imageresize
                  @dosyaisim="dsyisim = $event"
                  @resim="BelediyeGenelBilgiler.kapak = $event"
                  @imgbase64="BelediyeGenelBilgiler.kapakbase64 = $event"
                />
              </div>
              <div class="col-xl-6 col-lg-6">
                <kapaksec :dosyaismi="dsykapak" />
                <profilresim :imageurl="BelediyeGenelBilgiler.anaresim" />
                <imageresizekapak
                  @dosyaisim="dsykapak = $event"
                  @resim="BelediyeGenelBilgiler.anaresim = $event"
                  @imgbase64="BelediyeGenelBilgiler.anaresimbase64 = $event"
                />
              </div>
            </div>
            <hr />
            <div class="row p-4">
              <!-- Belediye İletişim Bölümü -->
              <div class="col-xl-6 col-lg-6">
                <div class="basic-form">
                  <form>
                    <div class="form-group">
                      <span class="solGenelBilgi">Belediye Adı</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Belediye Adı"
                        v-model="BelediyeGenelBilgiler.belediyeadi"
                      />
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">İletişim Numarası</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="İletişim Numarası"
                        v-model="BelediyeGenelBilgiler.iletisim"
                      />
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">WhatsApp İletişim</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Whatsapp İhbar Hattı"
                        v-model="BelediyeGenelBilgiler.whatsapp"
                      />
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">WhatsApp Mesaj</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Whatsapp Mesajı"
                        v-model="BelediyeGenelBilgiler.whatsappmesaj"
                      />
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">Facebook Mesaj</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Facebook Messenger"
                        v-model="BelediyeGenelBilgiler.facebookMessenger"
                      />
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">E-Mail Adresi</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Mail Adresi"
                        v-model="BelediyeGenelBilgiler.mail"
                      />
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">Kep Adresi</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="KEP Adresi"
                        v-model="BelediyeGenelBilgiler.kepAdresi"
                      />
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">Belediye Adresi</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Belediye Adresi"
                        v-model="BelediyeGenelBilgiler.belediyeadresi"
                      />
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">Şehir</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Belediye Merkez Şehir"
                        v-model="BelediyeGenelBilgiler.sehir"
                      />
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">Google Maps Iframe Kodu</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Belediye Google Maps Iframe Kodu"
                        v-model="BelediyeGenelBilgiler.haritaIframe"
                      />
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <span class="solGenelBilgi">Enlem</span>
                          <input
                            type="text"
                            class="form-control input-default"
                            placeholder="Belediye Adresi Enlem"
                            v-model="BelediyeGenelBilgiler.belediyeAdresiEnlem"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <span class="solGenelBilgi">Boylam</span>
                          <input
                            type="text"
                            class="form-control input-default"
                            placeholder="Belediye Adresi Boylam"
                            v-model="BelediyeGenelBilgiler.belediyeAdresiBoylam"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">Fax Numarası</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Fax Numarası"
                        v-model="BelediyeGenelBilgiler.faxnumarasi"
                      />
                    </div>
                  </form>
                </div>
              </div>
              <!-- Belediye Sosyal Medya Bölümü -->
              <div class="col-xl-6 col-lg-6">
                <div class="basic-form">
                  <form>
                    <div class="form-group">
                      <span class="solGenelBilgi">Şehir Kameraları Link</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Şehir Kameraları Linki"
                        v-model="BelediyeGenelBilgiler.cityCamera"
                      />
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">Facebook Link</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Facebook Link"
                        v-model="BelediyeGenelBilgiler.face"
                      />
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">Instagram Link</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="İnstagram Link"
                        v-model="BelediyeGenelBilgiler.insta"
                      />
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">Twitter Link</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Twitter Link"
                        v-model="BelediyeGenelBilgiler.twit"
                      />
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">E-Belediye Link</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="E-Belediye Links"
                        v-model="BelediyeGenelBilgiler.ebelediye"
                      />
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">Gezi Rehberi Link</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Gezi Rehberi Link"
                        v-model="BelediyeGenelBilgiler.geziRehberi"
                      />
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">Nöbetçi Ecz. Link</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Nöbetçi Ecz. Link"
                        v-model="BelediyeGenelBilgiler.nobetciecz"
                      />
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">Android Uygulama Link</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="Android Link"
                        v-model="BelediyeGenelBilgiler.android"
                      />
                    </div>
                    <div class="form-group">
                      <span class="solGenelBilgi">IOS Uygulama Link</span>
                      <input
                        type="text"
                        class="form-control input-default"
                        placeholder="İos Link"
                        v-model="BelediyeGenelBilgiler.Ios"
                      />
                    </div>
                    <div class="row">
                      <div class="form-group ml-3">
                        <span class="solGenelBilgi">Ana Renk Hex Kodu</span>
                        <input
                          type="text"
                          class="form-control input-default"
                          placeholder="Ana Renk"
                          v-model="BelediyeGenelBilgiler.anarenk"
                        />
                      </div>
                      <div class="form-group ml-3">
                        <span class="solGenelBilgi">Header Renk Hex Kodu</span>

                        <input
                          type="text"
                          class="form-control input-default"
                          placeholder="Header Renk"
                          v-model="BelediyeGenelBilgiler.headrenk"
                        />
                      </div>
                      <div class="form-group ml-3">
                        <span class="solGenelBilgi">Mobil Renk Hex Kodu</span>

                        <input
                          type="text"
                          class="as_colorpicker form-control"
                          placeholder="Mobil Renk"
                          v-model="BelediyeGenelBilgiler.mobilrenk"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <button
              id="mdbtn1"
              type="button"
              class="btn btn-outline-primary ml-5 mr-5"
              data-toggle="modal"
              @click="genelfonk()"
            >
              Güncelle
            </button>
            <div class="bosluk"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import profilresim from "@/components/baskan/profilresim.vue";
import imageresize from "@/components/yardimcilar/imageresize.vue";
import dosyasecbtn from "@/components/yardimcilar/dosyasecbtn.vue";
import NavSide from "@/components/home/nav-side.vue";
import imageresizekapak from "@/components/yardimcilar/imageresizekapak.vue";
import kapaksec from "@/components/yardimcilar/kapaksec.vue";
export default {
  name: "Genel",
  data() {
    return {
      kontrol: true,
      BelediyeGenelBilgiler: {
        belediyeadi: "",
        belediyeadresi: "",
        sehir: "",
        faxnumarasi: "",
        cityCamera: "",
        whatsapp: "",
        whatsappmesaj: "",
        facebookMessenger: "",
        ilceadi: "",
        iletisim: "",
        kapak: "",
        kapakbase64: "",
        anaresim: "",
        anaresimbase64: "",
        mail: "",
        kepAdresi: "",
      },
      dsyisim: "Belediye Logosu Seçiniz!",
      dsykapak: "Kapak Resmi Seçiniz!",
    };
  },
  computed: {
    progress() {
      return this.$store.getters.getProgress;
    },
  },
  async created() {
    await this.$store.dispatch("vericekme", "Genel");
    this.BelediyeGenelBilgiler = {
      ...this.BelediyeGenelBilgiler,
      ...this.$store.getters.getBelgenel,
    };
    console.log(this.BelediyeGenelBilgiler);
  },
  components: {
    NavSide,
    profilresim,
    imageresize,
    dosyasecbtn,
    imageresizekapak,
    kapaksec,
  },

  methods: {
    async genelfonk() {
      if (this.BelediyeGenelBilgiler.belediyeadi == "") {
        this.$root.$refs.toast.toastgoster("hata", "Belediye adı giriniz !");
      } else if (this.BelediyeGenelBilgiler.iletisim == "") {
        this.$root.$refs.toast.toastgoster(
          "hata",
          "İletişim numarası giriniz !"
        );
      } else if (this.BelediyeGenelBilgiler.mail == "") {
        this.$root.$refs.toast.toastgoster("hata", "E-posta adresi giriniz !");
      } else if (this.BelediyeGenelBilgiler.belediyeadresi == "") {
        this.$root.$refs.toast.toastgoster("hata", "Belediye adresi giriniz !");
      } else if (this.BelediyeGenelBilgiler.sehir == "") {
        this.$root.$refs.toast.toastgoster("hata", "Belediye şehiri giriniz !");
      } else if (this.BelediyeGenelBilgiler.faxnumarasi == "") {
        this.$root.$refs.toast.toastgoster("hata", "Fax numarası giriniz !!!");
      } else {
        if (this.dsyisim != "Belediye Logosu Seçiniz!") {
          const dataekle = {
            dosyakontrol: false,
            dosyalar: [this.BelediyeGenelBilgiler.kapak],
            kapak: this.BelediyeGenelBilgiler.kapak,
            col: "Genel",
            key: "Genel",
            ref: "Genel" + "/" + "kapak",
            dataekle: this.BelediyeGenelBilgiler,
          };
          this.kontrol = false;
          document.getElementById("main-wrapper").style.opacity = "0";
          await this.$store.dispatch("veriguncelledosyali", dataekle);
          if (this.dsykapak != "Slide Resim Seçiniz.") {
            const dataekle2 = {
              dosyakontrol: false,
              dosyalar: [this.BelediyeGenelBilgiler.anaresim],
              anaresim: this.BelediyeGenelBilgiler.anaresim,
              col: "Genel",
              key: "Genel",
              ref: "Genel" + "/" + "anaresim",
              dataekle: this.BelediyeGenelBilgiler,
            };
            await this.$store.dispatch("anaresim", dataekle2);
          }
          this.kontrol = true;
          document.getElementById("main-wrapper").style.opacity = "1";
          this.$root.$refs.toast.toastgoster(
            "basari",
            "Güncelleme Fotoğraflı Başarılı"
          );
        } else if (this.dsykapak != "Slide Resim Seçiniz.") {
          const dataekle2 = {
            dosyakontrol: false,
            //dosyalar: [this.BelediyeGenelBilgiler.anaresim],
            anaresim: this.BelediyeGenelBilgiler.anaresim,
            col: "Genel",
            key: "Genel",
            ref: "Genel" + "/" + "anaresim",
            dataekle: this.BelediyeGenelBilgiler,
          };
          this.kontrol = false;
          document.getElementById("main-wrapper").style.opacity = "0";
          await this.$store.dispatch("anaresim", dataekle2);
          this.kontrol = true;
          document.getElementById("main-wrapper").style.opacity = "1";
          this.$root.$refs.toast.toastgoster("basari", "Güncelleme Başarılı");
        } else {
          const dataekle = {
            col: "Genel",
            key: "Genel",
            dataekle: this.BelediyeGenelBilgiler,
          };
          await this.$store.dispatch("veriguncelle", dataekle);
          this.$root.$refs.toast.toastgoster("basari", "Güncelleme Başarılı");
        }
        this.dsyisim = "Belediye Logosu Seçiniz!";
        this.dsykapak = "Kapak Resmi Seçiniz!";
      }
    },
    // toBase64() {
    //   if (typeof this.BelediyeGenelBilgiler.kapak != "string") {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(this.BelediyeGenelBilgiler.kapak);
    //     reader.onload = () => {
    //       this.imageBase64 = reader.result;
    //     };
    //     reader.onerror = (error) => console.log(error);
    //   } else this.imageBase64 = this.BelediyeGenelBilgiler.kapak;
    // },
  },
  mounted() {
    document.getElementById("main-wrapper").style.opacity = "0";
    let customscript = document.createElement("script");
    customscript.setAttribute("src", "/js/custom.min.js");
    document.head.appendChild(customscript);

    let colorscript = document.createElement("script");
    colorscript.setAttribute(
      "src",
      "/js/plugins-init/jquery-asColorPicker.init.js"
    );
    document.head.appendChild(colorscript);

    let deznavscript = document.createElement("script");
    deznavscript.setAttribute("src", "/js/deznav-init.js");
    document.head.appendChild(deznavscript);
    setTimeout(() => {
      this.kontrol = false;
      document.getElementById("main-wrapper").style.opacity = "1";
    }, 100);
  },
};
</script>
<style>
.bosluk {
  padding-bottom: 100px;
}
.solGenelBilgi {
  display: inline-flex;
  float: left;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.568);
}
.solGenelBilgi::before {
  content: "";
  border-left: 3px solid red;
  border-radius: 50%;
  height: 3px;
  margin-right: 4px;
}
</style>
