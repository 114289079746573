<template>
  <div class="secbtn">
    <div class="col-xl-10 col-lg-10">
      <div class="basic-form custom_file_input">
        <div class="input-group mb-3">
          <div class="custom-file">
            <input
              id="file"
              type="file"
              accept="image/*"
              @change="bar"
              multiple="false"
              class="custom-file-input"
            />
            <label class="custom-file-label">{{ dosyaismi }}</label>
          </div>
          <div class="input-group-append">
            <span class="input-group-text">Yükle</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    dosyaismi: String,
  },
  methods: {
    bar(e) {
      this.$root.$refs.A.compress(e);
    },
  },
};
</script>
<style>
.secbtn {
  padding-top: 20px;
}
</style>
