<template>
  <div class="h-100">
    <div class="authincation h-100">
      <div class="container h-100">
        <div class="row justify-content-center align-items-center">
          <div class="col-md-6">
            <div class="authincation-content">
              <div class="row no-gutters">
                <div class="col-xl-12">
                  <div class="auth-form">
                    <div class="d-flex justify-content-center">
                      <img src="@/assets/logo.png" alt="ZeplinX" />
                    </div>
                    <h4
                      class="text-center mt-5 mb-5"
                      style="font-size: 2em; font-weight: 600"
                    >
                      Giriş Yapın
                    </h4>
                    <div class="form-group">
                      <label class="mb-1">
                        <strong>E-posta adresi</strong>
                      </label>
                      <input
                        type="email"
                        v-model="email"
                        class="form-control"
                        placeholder="info@example.bel.tr"
                        style="color: #000; font-weight: 600"
                      />
                    </div>
                    <div class="form-group">
                      <label class="mb-1">
                        <strong>Şifre</strong>
                      </label>
                      <input
                        type="password"
                        v-model="sifre"
                        class="form-control"
                        placeholder="password"
                        style="color: #000; font-weight: 600"
                      />
                    </div>

                    <div class="text-center">
                      <button
                        type="submit"
                        @click="giris()"
                        class="btn btn-primary btn-block"
                      >
                        Giriş Yap
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from "firebase";
export default {
  name: "Login",
  data() {
    return {
      email: "",
      sifre: "",
    };
  },
  methods: {
    giris() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.sifre)
        .then((user) => {
          this.$store.dispatch("fbgiris");
          if (user.user.uid == "rYyFbLjatHPU7I8bjyFMyFLAnpZ2") {
            this.$router.push("/yeni");
          } else {
            this.$router.push("/home");
          }
          this.$store.commit("solkontroldegistir", "/home");
        })
        .catch(function (error1) {
          console.log(error1);
          alert("Lütfen Geçerli Bir E-posta Adresi Veya Şifre Giriniz!");
        });
    },
  },
};
</script>
<style>
.center {
  margin: auto;
  border: 3px solid green;
  padding: 10px;
}
</style>