export const silmekontdeg = (state, kont) => {
    state.silmekont = kont;
};
export const domainIdbelirle = (state, domainId2) => {
    state.domainId = domainId2;
};
export const domainnamebelirle = (state, domainname) => {
    state.domainName = domainname;
};
export const tesyazidegistir = (state, aa) => {
    state.testyazi = aa;
};
export const solkontroldegistir = (state, path1) => {
    if (path1 == "/" || path1 == "/login") {
        state.solkontrol = false;
    } else {
        state.solkontrol = true;
    }
};

export const setUserUID = (state, uuid) => {
    state.userUID = uuid;
};

export const kontrolleridegistir = (state, hangisi) => {
    switch (hangisi) {
        case "belgenel":
            state.belgenelkont = false;
            break;
        case "baskangenel":
            state.baskangenel = false;
            break;
        case "baskanozgecmis":
            state.baskanozgecmiskont = false;
            break;
            // case "kuruluslar":
            // state.kuruluslarkont = false;
            // break;
        case "baskangorev":
            state.baskangorevkont = false;
            break;
        case "etikkomisyonu":
            state.etikkomisyonukont = false;
            break;
        case "baskanmesaj":
            state.baskanmesajkont = false;
            break;
        case "birimler":
            state.birimlerkont = false;
            break;
        case "destekler":
            state.desteklerkont = false;
            break;
        case "meclisuyeler":
            state.meclisuyekont = false;
            break;
        case "muhtarlar":
            state.muhtarlarkont = false;
            break;
        case "encumenlar":
            state.encumenlarkont = false;
            break;
        case "personlar":
            state.personlarkont = false;
            break;
        case "eskibaskanlar":
            state.eskibaskanlarkont = false;
            break;
        case "kararlar":
            state.kararlarkont = false;
            break;
        case "mailler":
            state.maillerkont = false;
            break;
        case "mevzuatlar":
            state.mevzuatlarkont = false;
            break;
        case "cralanlar":
            state.cralanlarkont = false;
            break;
        case "stratejikler":
            state.stratejiklerkont = false;
            break;
        case "suanalizler":
            state.suanalizkont = false;
            break;
        case "meclisgundemler":
            state.meclisgundemlerkont = false;
            break;
        case "organizasyonlar":
            state.organizasyonlarkont = false;
            break;
        case "otobusler":
            state.otobuslerkont = false;
            break;
        case " ":
            state.meclisuyekont = false;
            break;
        case "  ":
            state.meclisuyekont = false;
            break;
        case "   ":
            state.meclisuyekont = false;
            break;
        case "a":
            state.meclisuyekont = false;
            break;
        case "aa":
            state.meclisuyekont = false;
            break;
        case "aaa":
            state.meclisuyekont = false;
            break;
        case "ab":
            state.meclisuyekont = false;
            break;
        case "aba":
            state.meclisuyekont = false;
            break;
        case "abaa":
            state.meclisuyekont = false;
            break;
        case "abaac":
            state.meclisuyekont = false;
            break;
        case "abacaa":
            state.meclisuyekont = false;
            break;
        case "abaca1":
            state.meclisuyekont = false;
            break;
        default:
            break;
    }
};

/// FOTOGRAF VERİLERİ MANİPÜLE ET
//Progres manipüle
export const progresdegistir = (state, yenitask) => {
    state.uploadTask = yenitask;
    // yenitask.on('state_changed',function(snapshot){
    //     var progres1=(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //     console.log('yeniprogres'+progres1);
    //     state.progres=progres1;
    // })
};
export const progresyeni = (state, yeniprogres) => {
    console.log("bb" + yeniprogres);
    state.progres1 = yeniprogres;
};

///ÇEKİLEN VERİLERİ MANİPÜLE ET
//Belediye Genel Bilgileri
export const belgeneldeg = (state, genelbilgiler) => {
    state.BelediyeGenelBilgiler = genelbilgiler;
    state.belgenelkont = true;
};
//Belediye Genel Bilgileri
export const mobilgeneldeg = (state, mobilgenelbilgiler) => {
    state.MobilGenelBilgiler = mobilgenelbilgiler;
    state.mobilgenelkont = true;
};
// Baskan Genel Bilgileri
export const baskangeneldeg = (state, yenibaskangenel) => {
    state.BaskanGenelBilgiler = yenibaskangenel;
    state.baskangenelkont = true;
};
// Baskan Özgeçmiş Bilgileri
export const baskanozgecmis = (state, ozgecmis) => {
    state.BaskanOzgecmis = ozgecmis;
    state.baskanozgecmiskont = true;
};
// Kuruluşlar Bilgileri
// export const kuruluslar = (state, kuruluslar) => {
//   state.Kuruluslar = kuruluslar;
//   state.kuruluslarkont = true;
// };
// Baskan Görev Bilgileri
export const baskangorev = (state, gorev) => {
    state.BaskanGorev = gorev;
    state.baskangorevkont = true;
};
// Etik Komisyonu Bilgileri
export const etikkomisyonu = (state, komisyon) => {
    state.EtikKomisyonu = komisyon;
    state.etikkomisyonukont = true;
};
// Baskan Mesaj Bilgileri
export const baskanmesaj = (state, ozgecmis) => {
    state.BaskanMesaj = ozgecmis;
    state.baskanmesajkont = true;
};
// Birimler
export const birimler = (state, birimlist) => {
    state.Birimler = birimlist;
    state.birimlerkont = true;
};
// Destekler
export const destekler = (state, desteklist) => {
    state.Destekler = desteklist;
    state.desteklerkont = true;
};
// Meclis Uyeler
export const meclisuyeler = (state, uyelist) => {
    state.MeclisUyeler = uyelist;
    state.meclisuyekont = true;
};
// Muhtarlar
export const muhtarlar = (state, muhtarlist) => {
    state.Muhtarlar = muhtarlist;
    state.muhtarlarkont = true;
};
// Encumenlar
export const encumenlar = (state, encumenlist) => {
    state.Encumenlar = encumenlist;
    state.encumenlarkont = true;
};
// Personlar
export const personlar = (state, personlist) => {
    state.Personlar = personlist;
    state.personlarkont = true;
};
// EskiBaskanlar
export const eskibaskanlar = (state, eskibaskanlist) => {
    state.EskiBaskanlar = eskibaskanlist;
    state.eskibaskanlarkont = true;
};
// Kararlar
export const kararlar = (state, kararlist) => {
    state.Kararlar = kararlist;
    state.kararlarkont = true;
};
// Mailler
export const mailler = (state, maillist) => {
    state.Mailler = maillist;
    state.maillerkont = true;
};
// Mevzuatlar
export const mevzuatlar = (state, mevzuatlist) => {
    state.Mevzuatlar = mevzuatlist;
    state.mevzuatlarkont = true;
};
// Cralanlar
export const cralanlar = (state, cralanlist) => {
    state.Cralanlar = cralanlist;
    state.cralanlarkont = true;
};
// Stratejikler
export const stratejikler = (state, stratejiklist) => {
    state.Stratejikler = stratejiklist;
    state.stratejiklerkont = true;
};
// Suanalizler
export const suanalizler = (state, suanalizlist) => {
    state.Suanalizler = suanalizlist;
    state.suanalizkont = true;
};
// MeclisGundemler
export const meclisgundemler = (state, meclisgundemlist) => {
    state.MeclisGundemler = meclisgundemlist;
    state.meclisgundemkont = true;
};
// Organizasyonlar
export const organizasyonlar = (state, organizasyonlist) => {
    state.Organizasyonlar = organizasyonlist;
    state.organizasyonlarkont = true;
};
// Otobusler
export const otobusler = (state, otobuslist) => {
    state.Otobusler = otobuslist;
    state.otobuslerkont = true;
};

export const adminDestekMutation = (state, adminDestekData) => {
    state.AdminDestek = adminDestekData;
    state.admindestekkontrol = true
}

export const genelmutation = (state, databilgisi) => {
    switch (databilgisi.col) {
        case "Kararlar":
            state.Kararlar = databilgisi.data;
            state.kararlarkontrol = true;
            break;
        case "Mailler":
            state.Mailler = databilgisi.data;
            state.maillerkontrol = true;
            break;
        case "Mevzuatlar":
            state.Mevzuatlar = databilgisi.data;
            state.mevzuatlarkontrol = true;
            break;
        case "Cralanlar":
            state.Cralanlar = databilgisi.data;
            state.cralanlarkontrol = true;
            break;
        case "Stratejikler":
            state.Stratejikler = databilgisi.data;
            state.stratejiklerkontrol = true;
            break;
        case "Suanalizler":
            state.Suanalizler = databilgisi.data;
            state.suanalizlerkontrol = true;
            break;
        case "MeclisGundemler":
            state.MeclisGundemler = databilgisi.data;
            state.meclisgundemlerkontrol = true;
            break;
        case "Organizasyonlar":
            state.Organizasyonlar = databilgisi.data;
            state.organizasyonlarkontrol = true;
            break;
        case "Otobusler":
            state.Otobusler = databilgisi.data;
            state.otobuslerkontrol = true;
            break;
        case "Personhaberler":
            state.Personhaberler = databilgisi.data;
            state.personhaberlerkontrol = true;
            break;
        case "Ihale":
            state.Ihale = databilgisi.data;
            state.ihalekontrol = true;
            break;
        case "Sonucihale":
            state.Sonucihale = databilgisi.data;
            state.sonucihalekontrol = true;
            break;
        case "Muhtarlar":
            state.Muhtarlar = databilgisi.data;
            state.muhtarlarkontrol = true;
            break;
        case "Encumenlar":
            state.Encumenlar = databilgisi.data;
            state.encumenlarkontrol = true;
            break;
        case "Personlar":
            state.Personlar = databilgisi.data;
            state.personlarkontrol = true;
            break;
        case "EskiBaskanlar":
            state.EskiBaskanlar = databilgisi.data;
            state.eskibaskanlarkontrol = true;
            break;
        case "Uyeler":
            state.Uyeler = databilgisi.data;
            state.uyelerkontrol = true;
            break;
        case "Birimler":
            state.Birimler = databilgisi.data;
            state.birimlerkontrol = true;
            break;
        case "Destekler":
            state.Destekler = databilgisi.data;
            state.desteklerkontrol = true;
            break;
        case "Raporlar":
            state.Raporlar = databilgisi.data;
            state.raporlarkontrol = true;
            break;
        case "Raporlar2":
            state.Raporlar2 = databilgisi.data;
            state.raporlar2kontrol = true;
            break;
        case "Baskan":
            state.BaskanBilgileri = databilgisi.data;
            state.baskankontrol = true;
            break;
        case "Genel":
            state.BelediyeGenelBilgiler = databilgisi.data;
            state.genelkontrol = true;
            break;
        case "MobilGenel":
            state.MobilGenelBilgiler = databilgisi.data;
            state.mobilgenelkontrol = true;
            break;
        case "Projeler":
            state.Projeler = databilgisi.data;
            state.projekontrol = true;
            break;
        case "Tadilatlar":
            state.Tadilatlar = databilgisi.data;
            state.tadilatkontrol = true;
            break;
        case "Haberler":
            state.Haberler = databilgisi.data;
            state.haberkontrol = true;
            break;
        case "PratikRehber":
            state.PratikRehber = databilgisi.data;
            state.pratikkontrol = true;
            break;
        case "Ilce":
            state.IlceBilgiler = databilgisi.data;
            state.ilcekontrol = true;
            break;
        case "Slides":
            state.Slides = databilgisi.data;
            state.slideskontrol = true;
            break;
        case "Sehitler":
            state.Sehitler = databilgisi.data;
            state.sehitkontrol = true;
            break;
        case "Yerler":
            state.Yerler = databilgisi.data;
            state.yerkontrol = true;
            break;
        case "SosyalTesis":
            state.SosyalTesis = databilgisi.data;
            state.sosyalkontrol = true;
            break;
        case "CovidRehber":
            state.CovidRehber = databilgisi.data;
            state.covidkontrol = true;
            break;
        case "Vefatlar":
            state.Vefatlar = databilgisi.data;
            state.vefatkontrol = true;
            break;
        case "Bildirimler":
            state.Bildirimler = databilgisi.data;
            state.bildirimkontrol = true;
            break;
        case "Evlenenler":
            state.Evlenenler = databilgisi.data;
            state.evlenenkontrol = true;
            break;
        case "Videolar":
            state.Videolar = databilgisi.data;
            state.videokontrol = true;
            break;
        case "Belgeler":
            state.Belgeler = databilgisi.data;
            state.belgekontrol = true;
            break;
        case "FastDocs":
            state.FastDocs = databilgisi.data;
            state.fastdockontrol = true;
            break;
        case "DuyuruIlanlar":
            state.DuyuruIlanlar = databilgisi.data;
            state.duyuruilankontrol = true;
            break;
        case "Menuler":
            state.Menuler = databilgisi.data;
            state.menukontrol = true;
            break;
        default:
            break;
    }
};